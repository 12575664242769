import { ArrowRight, X } from 'lucide-react';
import { useState } from 'react';
import { Swiper as SwiperClass } from 'swiper';
import { EffectFade } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/effect-fade';

import { useEffect, useRef } from 'react';

const InvertedArcCanvas = ({ color }: { color: string }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const size = 39;
    const scale = (window.devicePixelRatio || 1) * 3; // 3배 해상도 적용
    canvas.width = size * scale;
    canvas.height = size * scale;
    canvas.style.width = `${size}px`;
    canvas.style.height = `${size}px`;

    ctx.scale(scale, scale); // 3배 크기로 스케일링

    // 배경 색 채우기
    ctx.fillStyle = color;
    ctx.fillRect(0, 0, size, size);

    // 곡선을 부드럽게 그리기 위한 설정
    ctx.globalCompositeOperation = 'destination-out';
    ctx.beginPath();
    ctx.arc(size, size, size, Math.PI, (3 * Math.PI) / 2, false);
    ctx.lineTo(size, size);
    ctx.closePath();
    ctx.fill();

    // 원래 상태로 되돌림
    ctx.globalCompositeOperation = 'source-over';
  }, [color]);

  return <canvas ref={canvasRef} />;
};

export default function NoticePopup() {
  const [open, setOpen] = useState(true);

  const [swiperRef, setSwiperRef] = useState<SwiperClass | null>(null);

  const goNext = () => {
    if (!swiperRef) return;

    if (swiperRef.isEnd) {
      setOpen(false);
    } else {
      swiperRef.slideNext();
    }
  };

  if (!open) return null;

  return (
    <div className='fixed w-dvw h-dvh left-0 top-0 flex items-center justify-center z-[9999] bg-black/60'>
      {/* Content */}
      <div className='w-full px-5'>
        <div className='flex justify-end items-center pb-5'>
          <X
            onClick={() => {
              setOpen(false);
            }}
            className='text-white stroke-[1.5] cursor-pointer'
            size={30}
          />
        </div>

        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          effect='fade'
          modules={[EffectFade]}
          onSwiper={setSwiperRef}
          initialSlide={0}
        >
          {/* First content */}
          <SwiperSlide>
            {/* Image */}
            <div className='rounded-t-[24px] h-[200px] bg-[#F8F9FA] flex items-center justify-center'>
              <img
                src='/imgs/png/service/popup/note.png'
                alt='노트'
                className='w-[130px] h-[130px]'
              />
            </div>
            {/* Body */}
            <div className='bg-[#FFF] rounded-br-[24px] pt-[26px] pb-10 px-[24px] h-[196px]'>
              <div className='flex gap-1.5 items-center mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <path
                    d='M5.58209 14.2205C5.58209 13.3122 4.84579 12.5759 3.93753 12.5759C3.02926 12.5759 2.29297 13.3122 2.29297 14.2205V15.8384C2.29297 16.7466 3.02926 17.4829 3.93753 17.4829C4.84579 17.4829 5.58209 16.7466 5.58209 15.8384V14.2205Z'
                    fill='#333331'
                  />
                  <path
                    d='M17.3751 14.2205C17.3751 13.3122 16.6388 12.5759 15.7305 12.5759C14.8222 12.5759 14.0859 13.3122 14.0859 14.2205V15.8384C14.0859 16.7466 14.8222 17.4829 15.7305 17.4829C16.6388 17.4829 17.3751 16.7466 17.3751 15.8384V14.2205Z'
                    fill='#333331'
                  />
                  <path
                    d='M18.3842 8.79155C18.9096 8.79155 19.3359 8.36518 19.3359 7.83984C19.3359 7.31449 18.9134 6.88812 18.3842 6.88812H17.5391C17.1622 6.88812 16.8424 7.10892 16.6864 7.42489C16.6483 7.36398 16.614 7.29926 16.5798 7.23836C16.3399 6.83102 15.9022 4.78674 15.9022 4.78674C15.9022 4.78674 15.6547 2.12575 12.8186 1.94302C11.7755 1.87831 11.0865 1.86308 9.83784 1.87069C8.58919 1.86308 7.90015 1.8745 6.85708 1.94302C4.02097 2.12195 3.77352 4.78674 3.77352 4.78674C3.77352 4.78674 3.33574 6.83102 3.09591 7.23836C3.06164 7.29926 3.02358 7.36017 2.98931 7.42489C2.83323 7.10892 2.51346 6.88812 2.14039 6.88812H1.29526C0.762305 6.88432 0.335937 7.31069 0.335937 7.83603C0.335937 8.36138 0.758498 8.78774 1.28765 8.78774H1.59981C0.99833 9.43872 0.990716 10.3486 0.990716 10.3486C0.990716 10.3486 0.785146 11.3574 0.994523 13.2951C1.20771 15.2784 3.74307 15.1718 3.74307 15.1718H15.9326C15.9326 15.1718 18.468 15.2822 18.6812 13.2951C18.8905 11.3574 18.685 10.3486 18.685 10.3486C18.685 10.3486 18.6774 9.43872 18.0759 8.78774H18.3842V8.79155Z'
                    fill='#4860FF'
                  />
                  <path
                    d='M14.3516 5.81878C14.3516 5.81878 14.0128 3.61461 12.0523 3.52325C11.2719 3.48518 10.7617 3.45853 9.83288 3.46615C8.904 3.45853 8.39008 3.45853 7.61348 3.52325C5.63392 3.68694 5.31414 5.81878 5.31414 5.81878C5.31414 5.81878 5.08954 6.79333 4.92204 7.48999C4.85732 7.76027 5.07812 7.79073 5.07812 7.79073C5.07812 7.79073 5.48545 7.84022 9.88617 7.84022C14.2869 7.84022 14.6105 7.79073 14.6105 7.79073C14.6105 7.79073 14.8122 7.79073 14.7437 7.4976C14.6029 6.90373 14.3516 5.82259 14.3516 5.82259V5.81878Z'
                    fill='white'
                  />
                  <path
                    d='M2.63088 12.6101C2.38343 12.6101 2.15121 12.4464 2.08269 12.1952L1.85428 11.3843C1.76672 11.0797 1.94564 10.7638 2.25019 10.68C2.55093 10.5925 2.87071 10.7714 2.95446 11.0759L3.18287 11.8868C3.27043 12.1913 3.0915 12.5073 2.78696 12.5911C2.73366 12.6063 2.68417 12.6139 2.63088 12.6139V12.6101Z'
                    fill='#FFDE8B'
                  />
                  <path
                    d='M17.0416 12.61C16.9884 12.61 16.9389 12.6024 16.8856 12.5872C16.581 12.4996 16.4059 12.1875 16.4897 11.8829L16.7181 11.0721C16.8056 10.7675 17.1216 10.5924 17.4223 10.6761C17.7269 10.7637 17.902 11.0759 17.8182 11.3804L17.5898 12.1913C17.5175 12.4425 17.2891 12.6062 17.0416 12.6062V12.61Z'
                    fill='#FFDE8B'
                  />
                  <path
                    d='M12.3007 12.3741H7.37465C6.95416 12.3741 6.61328 12.715 6.61328 13.1355V13.2992C6.61328 13.7197 6.95416 14.0606 7.37465 14.0606H12.3007C12.7212 14.0606 13.0621 13.7197 13.0621 13.2992V13.1355C13.0621 12.715 12.7212 12.3741 12.3007 12.3741Z'
                    fill='#1938FF'
                  />
                </svg>
                <div className='text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                  신차 구매
                </div>
              </div>
              <div className='text-[#222] text-[18px] font-semibold leading-[27px] tracking-[-0.45px] mb-3'>
                원하는 차량의 견적을 한 번에!
              </div>
              <div className='text-[#555] text-[14px] font-normal leading-[22.4px] tracking-[-0.35px]'>
                구매 유형별(리스, 장기렌트, 할부, 일시불)로 전국 최대 카
                매니저&네트워크 인프라를 활용하여 전국의 다양한 견적을
                실시간으로 비교할 수 있습니다.
              </div>
            </div>
            {/* Footer */}
            <div className='flex justify-between'>
              <div className='flex justify-start items-end flex-auto bg-[#FFF] rounded-b-[26px] px-6 pb-6 gap-1.5'>
                <div className='rounded-full w-[22px] h-2 bg-[#111]'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
              </div>
              <div className='relative w-[74px] h-[74px] flex items-end justify-end'>
                <div className='absolute top-0 left-0 w-full h-full'>
                  <InvertedArcCanvas color={'#FFF'} />
                </div>

                <div
                  className={
                    'cursor-pointer rounded-full z-20 w-[62px] h-[62px] flex items-center justify-center'
                  }
                  onClick={() => {
                    goNext();
                  }}
                >
                  <div className='absolute'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_1495_21901)'>
                        <path
                          d='M7.5 18H28.5'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 27L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 8.99995L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1495_21901'>
                          <rect width='36' height='36' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='62'
                    height='62'
                    viewBox='0 0 62 62'
                    fill='none'
                  >
                    <circle
                      cx='31'
                      cy='31'
                      r='30'
                      fill='white'
                      stroke='url(#paint0_linear_1495_21900)'
                      strokeWidth='2'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_1495_21900'
                        x1='0'
                        y1='0'
                        x2='62'
                        y2='62'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#DDDDE8' />
                        <stop offset='1' stopColor='#A0A1B0' />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* Second content */}
          <SwiperSlide>
            {/* Image */}
            <div className='rounded-t-[24px] h-[200px] bg-[#F8F9FA] flex items-center justify-center'>
              <img
                src='/imgs/png/service/popup/phone.png'
                alt='노트'
                className='w-[130px] h-[130px]'
              />
            </div>
            {/* Body */}
            <div className='bg-[#7273F9] rounded-br-[24px] pt-[26px] pb-10 px-[24px] h-[196px]'>
              <div className='flex gap-1.5 items-center mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <g clipPath='url(#clip0_1505_21991)'>
                    <path
                      d='M15.2595 14.087V1.36973C15.2595 0.72235 14.5965 0.286708 14.0023 0.543663L11.9972 1.41074C11.7692 1.50932 11.5107 1.50932 11.2827 1.41074L8.3776 0.154473C8.14965 0.0559019 7.89111 0.055902 7.66316 0.154474L4.75803 1.41074C4.53009 1.50932 4.27154 1.50932 4.0436 1.41074L2.03847 0.543663C1.44426 0.286708 0.78125 0.722349 0.78125 1.36973V16.0435C0.78125 17.1235 1.65777 18 2.73777 18H15.2595V14.087Z'
                      fill='white'
                    />
                    <path
                      d='M15.26 18.0002C16.34 18.0002 17.2165 17.1236 17.2165 16.0436C17.2165 14.9636 16.34 14.0871 15.26 14.0871H2.73828C3.81828 14.0871 4.6948 14.9636 4.6948 16.0436C4.6948 17.1236 3.81828 18.0002 2.73828 18.0002H15.26Z'
                      fill='#B8B8FF'
                    />
                    <path
                      d='M5.2377 8.88265H4.10683C3.67639 8.88265 3.32422 8.53048 3.32422 8.10004C3.32422 7.66961 3.67639 7.31743 4.10683 7.31743H5.2377C5.66813 7.31743 6.02031 7.66961 6.02031 8.10004C6.02031 8.53048 5.66813 8.88265 5.2377 8.88265Z'
                      fill='#7273F9'
                    />
                    <path
                      d='M11.933 8.88265H10.8021C10.3717 8.88265 10.0195 8.53048 10.0195 8.10004C10.0195 7.66961 10.3717 7.31743 10.8021 7.31743H11.933C12.3634 7.31743 12.7156 7.66961 12.7156 8.10004C12.7156 8.53048 12.3634 8.88265 11.933 8.88265Z'
                      fill='#7273F9'
                    />
                    <path
                      d='M9.7577 11.6998C9.44074 11.6998 9.15509 11.5081 9.03378 11.2146L8.01639 8.74547L7.00291 11.2146C6.88161 11.512 6.58422 11.7037 6.26335 11.6998C5.94248 11.6959 5.65683 11.4924 5.54335 11.1911L3.80596 6.49547C3.65726 6.08851 3.86465 5.63851 4.2677 5.48981C4.67074 5.34112 5.12465 5.5446 5.27335 5.95155L6.31813 8.76894L7.29639 6.39373C7.4177 6.10025 7.70335 5.90851 8.0203 5.90851C8.33726 5.90851 8.62291 6.10025 8.74422 6.39373L9.72248 8.76894L10.7673 5.95155C10.916 5.5446 11.366 5.33721 11.7729 5.48981C12.1799 5.63851 12.3833 6.08851 12.2347 6.49547L10.4973 11.1911C10.3838 11.4924 10.0981 11.6959 9.77726 11.6998C9.77335 11.6998 9.76943 11.6998 9.76161 11.6998H9.7577Z'
                      fill='#7273F9'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1505_21991'>
                      <rect
                        width='16.4348'
                        height='18'
                        fill='white'
                        transform='translate(0.78125)'
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className='text-[#FFF] text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                  내 차 팔기
                </div>
              </div>
              <div className='text-[#FFF] text-[18px] font-semibold leading-[27px] tracking-[-0.45px] mb-3'>
                시세부터 판매까지 감가 없이 안전하게!
              </div>
              <div className='text-white/90 text-[14px] font-normal leading-[22.4px] tracking-[-0.35px]'>
                국토교통부 인가 단체인 자동차매매사업조합연합회 소속 카
                매니저님의 견적을 정확하고 투명하게 확인할 수 있습니다.
              </div>
            </div>
            {/* Footer */}
            <div className='flex justify-between'>
              <div className='flex justify-start items-end flex-auto bg-[#7273F9] rounded-b-[24px] px-6 pb-6 gap-1.5'>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-[22px] h-2 bg-[#FFF]'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
              </div>
              <div className='relative w-[74px] h-[74px] flex items-end justify-end'>
                <div className='absolute top-0 left-0 w-full h-full'>
                  <InvertedArcCanvas color={'#7273F9'} />
                </div>
                <div
                  className={
                    'cursor-pointer rounded-full z-20 w-[62px] h-[62px] flex items-center justify-center'
                  }
                  onClick={() => {
                    goNext();
                  }}
                >
                  <div className='absolute'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_1495_21901)'>
                        <path
                          d='M7.5 18H28.5'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 27L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 8.99995L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1495_21901'>
                          <rect width='36' height='36' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='62'
                    height='62'
                    viewBox='0 0 62 62'
                    fill='none'
                  >
                    <circle
                      cx='31'
                      cy='31'
                      r='30'
                      fill='white'
                      stroke='url(#paint0_linear_1505_21974)'
                      strokeWidth='2'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_21974'
                        x1='0'
                        y1='0'
                        x2='62'
                        y2='62'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#BAC6FF' />
                        <stop offset='1' stopColor='#7374F9' />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* Third content */}
          <SwiperSlide>
            {/* Image */}
            <div className='rounded-t-[24px] h-[200px] bg-[#F8F9FA] flex items-center justify-center'>
              <div className='flex justify-center items-center gap-5'>
                <div>
                  <svg
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_68_20)'>
                      <path
                        d='M14.5856 49.5833C14.5856 51.1304 15.2002 52.6142 16.2941 53.7081C17.3881 54.8021 18.8718 55.4167 20.4189 55.4167C21.966 55.4167 23.4497 54.8021 24.5437 53.7081C25.6377 52.6142 26.2522 51.1304 26.2522 49.5833C26.2522 48.0362 25.6377 46.5525 24.5437 45.4585C23.4497 44.3646 21.966 43.75 20.4189 43.75C18.8718 43.75 17.3881 44.3646 16.2941 45.4585C15.2002 46.5525 14.5856 48.0362 14.5856 49.5833Z'
                        stroke='url(#paint0_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M43.7504 49.5833C43.7504 51.1304 44.3649 52.6142 45.4589 53.7081C46.5529 54.8021 48.0366 55.4167 49.5837 55.4167C51.1308 55.4167 52.6145 54.8021 53.7085 53.7081C54.8024 52.6142 55.417 51.1304 55.417 49.5833C55.417 48.0362 54.8024 46.5525 53.7085 45.4585C52.6145 44.3646 51.1308 43.75 49.5837 43.75C48.0366 43.75 46.5529 44.3646 45.4589 45.4585C44.3649 46.5525 43.7504 48.0362 43.7504 49.5833Z'
                        stroke='url(#paint1_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M14.5837 49.5833V49.5833C11.362 49.5833 8.75037 46.9717 8.75037 43.75V32.0833M8.75037 32.0833L12.0693 23.7861C13.5879 19.9895 17.265 17.5 21.354 17.5H36.0275C39.0653 17.5 41.9384 18.8809 43.8361 21.253L52.5004 32.0833M8.75037 32.0833H52.5004M52.5004 32.0833H55.417C56.9641 32.0833 58.4479 32.6979 59.5418 33.7919C60.6358 34.8858 61.2504 36.3696 61.2504 37.9167V43.75C61.2504 46.9717 58.6387 49.5833 55.417 49.5833V49.5833M43.7504 49.5833H26.2504M35.0004 32.0833V17.5'
                        stroke='url(#paint2_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_68_20'
                        x1='20.4189'
                        y1='43.75'
                        x2='20.4189'
                        y2='55.4167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint1_linear_68_20'
                        x1='49.5837'
                        y1='43.75'
                        x2='49.5837'
                        y2='55.4167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint2_linear_68_20'
                        x1='35.0004'
                        y1='17.5'
                        x2='35.0004'
                        y2='49.5833'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <clipPath id='clip0_68_20'>
                        <rect width='70' height='70' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_1505_22195)'>
                      <path
                        d='M26.2487 14.5831H20.4154C18.8683 14.5831 17.3845 15.1977 16.2906 16.2916C15.1966 17.3856 14.582 18.8693 14.582 20.4164V55.4164C14.582 56.9635 15.1966 58.4473 16.2906 59.5412C17.3845 60.6352 18.8683 61.2498 20.4154 61.2498H49.582C51.1291 61.2498 52.6129 60.6352 53.7068 59.5412C54.8008 58.4473 55.4154 56.9635 55.4154 55.4164V20.4164C55.4154 18.8693 54.8008 17.3856 53.7068 16.2916C52.6129 15.1977 51.1291 14.5831 49.582 14.5831H43.7487'
                        stroke='url(#paint0_linear_1505_22195)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M26.25 14.5833C26.25 13.0362 26.8646 11.5525 27.9585 10.4585C29.0525 9.36458 30.5362 8.75 32.0833 8.75H37.9167C39.4638 8.75 40.9475 9.36458 42.0415 10.4585C43.1354 11.5525 43.75 13.0362 43.75 14.5833C43.75 16.1304 43.1354 17.6142 42.0415 18.7081C40.9475 19.8021 39.4638 20.4167 37.9167 20.4167H32.0833C30.5362 20.4167 29.0525 19.8021 27.9585 18.7081C26.8646 17.6142 26.25 16.1304 26.25 14.5833Z'
                        stroke='url(#paint1_linear_1505_22195)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M26.25 35H43.75'
                        stroke='url(#paint2_linear_1505_22195)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M26.25 46.6669H43.75'
                        stroke='url(#paint3_linear_1505_22195)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_22195'
                        x1='34.9987'
                        y1='14.5831'
                        x2='34.9987'
                        y2='61.2498'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint1_linear_1505_22195'
                        x1='35'
                        y1='8.75'
                        x2='35'
                        y2='20.4167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint2_linear_1505_22195'
                        x1='35'
                        y1='35'
                        x2='35'
                        y2='36'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint3_linear_1505_22195'
                        x1='35'
                        y1='46.6669'
                        x2='35'
                        y2='47.6669'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <clipPath id='clip0_1505_22195'>
                        <rect width='70' height='70' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_1505_22201)'>
                      <path
                        d='M11.668 52.5002V17.5002C11.668 15.9531 12.2826 14.4694 13.3765 13.3754C14.4705 12.2815 15.9542 11.6669 17.5013 11.6669H52.5013C54.0484 11.6669 55.5321 12.2815 56.6261 13.3754C57.7201 14.4694 58.3346 15.9531 58.3346 17.5002V52.5002C58.3346 54.0473 57.7201 55.5311 56.6261 56.625C55.5321 57.719 54.0484 58.3336 52.5013 58.3336H17.5013C15.9542 58.3336 14.4705 57.719 13.3765 56.625C12.2826 55.5311 11.668 54.0473 11.668 52.5002Z'
                        stroke='url(#paint0_linear_1505_22201)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M20.418 40.8336L27.0466 34.2049C28.2182 33.0333 30.1177 33.0333 31.2893 34.2049L32.88 35.7956C34.0516 36.9672 35.9511 36.9672 37.1226 35.7956L41.63 31.2882C42.8016 30.1166 44.701 30.1166 45.8726 31.2882L49.5846 35.0002'
                        stroke='url(#paint1_linear_1505_22201)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_22201'
                        x1='35.0013'
                        y1='11.6669'
                        x2='35.0013'
                        y2='58.3336'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint1_linear_1505_22201'
                        x1='35.0013'
                        y1='29.1669'
                        x2='35.0013'
                        y2='40.8336'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <clipPath id='clip0_1505_22201'>
                        <rect width='70' height='70' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_1505_22205)'>
                      <path
                        d='M26.2487 20.4167H43.7487M26.2487 32.0833H43.7487M37.9154 43.75H43.7487M14.582 14.5833V59.3815C14.582 60.1802 15.4722 60.6566 16.1367 60.2135L23.332 55.4167L28.4583 60.5429C28.8488 60.9334 29.4819 60.9334 29.8725 60.5429L34.9987 55.4167L40.1249 60.5429C40.5154 60.9334 41.1486 60.9334 41.5391 60.5429L46.6654 55.4167L53.8607 60.2135C54.5252 60.6566 55.4154 60.1802 55.4154 59.3815V14.5833C55.4154 13.0362 54.8008 11.5525 53.7068 10.4585C52.6129 9.36458 51.1291 8.75 49.582 8.75H20.4154C18.8683 8.75 17.3845 9.36458 16.2906 10.4585C15.1966 11.5525 14.582 13.0362 14.582 14.5833Z'
                        stroke='url(#paint0_linear_1505_22205)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_22205'
                        x1='34.9987'
                        y1='8.75'
                        x2='34.9987'
                        y2='61.25'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <clipPath id='clip0_1505_22205'>
                        <rect width='70' height='70' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <div>
                  <svg
                    width='70'
                    height='70'
                    viewBox='0 0 70 70'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <g clipPath='url(#clip0_68_20)'>
                      <path
                        d='M14.5856 49.5833C14.5856 51.1304 15.2002 52.6142 16.2941 53.7081C17.3881 54.8021 18.8718 55.4167 20.4189 55.4167C21.966 55.4167 23.4497 54.8021 24.5437 53.7081C25.6377 52.6142 26.2522 51.1304 26.2522 49.5833C26.2522 48.0362 25.6377 46.5525 24.5437 45.4585C23.4497 44.3646 21.966 43.75 20.4189 43.75C18.8718 43.75 17.3881 44.3646 16.2941 45.4585C15.2002 46.5525 14.5856 48.0362 14.5856 49.5833Z'
                        stroke='url(#paint0_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M43.7504 49.5833C43.7504 51.1304 44.3649 52.6142 45.4589 53.7081C46.5529 54.8021 48.0366 55.4167 49.5837 55.4167C51.1308 55.4167 52.6145 54.8021 53.7085 53.7081C54.8024 52.6142 55.417 51.1304 55.417 49.5833C55.417 48.0362 54.8024 46.5525 53.7085 45.4585C52.6145 44.3646 51.1308 43.75 49.5837 43.75C48.0366 43.75 46.5529 44.3646 45.4589 45.4585C44.3649 46.5525 43.7504 48.0362 43.7504 49.5833Z'
                        stroke='url(#paint1_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M14.5837 49.5833V49.5833C11.362 49.5833 8.75037 46.9717 8.75037 43.75V32.0833M8.75037 32.0833L12.0693 23.7861C13.5879 19.9895 17.265 17.5 21.354 17.5H36.0275C39.0653 17.5 41.9384 18.8809 43.8361 21.253L52.5004 32.0833M8.75037 32.0833H52.5004M52.5004 32.0833H55.417C56.9641 32.0833 58.4479 32.6979 59.5418 33.7919C60.6358 34.8858 61.2504 36.3696 61.2504 37.9167V43.75C61.2504 46.9717 58.6387 49.5833 55.417 49.5833V49.5833M43.7504 49.5833H26.2504M35.0004 32.0833V17.5'
                        stroke='url(#paint2_linear_68_20)'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <linearGradient
                        id='paint0_linear_68_20'
                        x1='20.4189'
                        y1='43.75'
                        x2='20.4189'
                        y2='55.4167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint1_linear_68_20'
                        x1='49.5837'
                        y1='43.75'
                        x2='49.5837'
                        y2='55.4167'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <linearGradient
                        id='paint2_linear_68_20'
                        x1='35.0004'
                        y1='17.5'
                        x2='35.0004'
                        y2='49.5833'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#5DABFF' />
                        <stop offset='1' stopColor='#449EFF' />
                      </linearGradient>
                      <clipPath id='clip0_68_20'>
                        <rect width='70' height='70' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
            {/* Body */}
            <div className='bg-[#3395FF] rounded-br-[24px] pt-[26px] pb-10 px-[24px] h-[196px]'>
              <div className='flex gap-1.5 items-center mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <path
                    d='M11.3702 17.9511C10.6773 18.4557 9.73304 18.4557 9.04016 17.9511C6.31942 15.9697 2.29688 12.5744 2.29688 6.71094V5.42919C2.29688 5.00655 2.59869 4.64637 3.01025 4.55021C5.43348 3.98403 7.68383 2.8744 9.60362 1.30185C9.9523 1.01624 10.458 1.01624 10.8067 1.30188C12.7263 2.87465 14.9763 3.98607 17.3993 4.55062C17.8112 4.64661 18.1135 5.00694 18.1135 5.4299V6.71094C18.1135 12.5744 14.0909 15.9697 11.3702 17.9511Z'
                    fill='white'
                  />
                  <path
                    d='M7.56641 10.0767L9.39567 11.9059L13.0542 8.24741'
                    stroke='#3395FF'
                    strokeWidth='1.6'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
                <div className='text-[#FFF] text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                  다이렉트 자동차보험
                </div>
              </div>
              <div className='text-[#FFF] text-[18px] font-semibold leading-[27px] tracking-[-0.45px] mb-3'>
                자동차 보험료 한 번에 비교하고
                <br />
                혜택을 더해 가입!
              </div>
              <div className='text-white/90 text-[14px] font-normal leading-[22.4px] tracking-[-0.35px]'>
                나에게 맞는 가장 저렴한 보험사를 비교 후 결정하셨다면
                카사요(주)가 제휴한 보험사로 가입하여 최대 3만원 혜택을
                받으세요.
              </div>
            </div>
            {/* Footer */}
            <div className='flex justify-between'>
              <div className='flex justify-start items-end flex-auto bg-[#3395FF] rounded-b-[24px] px-6 pb-6 gap-1.5'>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-[22px] h-2 bg-[#FFF]'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
              </div>
              <div className='relative w-[74px] h-[74px] flex items-end justify-end'>
                <div className='absolute top-0 left-0 w-full h-full'>
                  <InvertedArcCanvas color={'#3395FF'} />
                </div>
                <div
                  className={
                    'cursor-pointer rounded-full z-20 w-[62px] h-[62px] flex items-center justify-center'
                  }
                  onClick={() => {
                    goNext();
                  }}
                >
                  <div className='absolute'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_1495_21901)'>
                        <path
                          d='M7.5 18H28.5'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 27L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 8.99995L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1495_21901'>
                          <rect width='36' height='36' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='62'
                    height='62'
                    viewBox='0 0 62 62'
                    fill='none'
                  >
                    <circle
                      cx='31'
                      cy='31'
                      r='30'
                      fill='white'
                      stroke='url(#paint0_linear_1505_22030)'
                      strokeWidth='2'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_22030'
                        x1='0'
                        y1='0'
                        x2='62'
                        y2='62'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#A9E5FF' />
                        <stop offset='1' stopColor='#3395FF' />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </SwiperSlide>
          {/* Forth content */}
          <SwiperSlide>
            {/* Image */}
            <div className='rounded-t-[24px] h-[200px] bg-[#F8F9FA] flex items-center justify-center'>
              <img
                src='/imgs/png/service/popup/hammer.png'
                alt='노트'
                className='w-[130px] h-[130px]'
              />
            </div>
            {/* Body */}
            <div className='bg-[#008E6F] rounded-br-[24px] pt-[26px] pb-10 px-[24px] h-[196px]'>
              <div className='flex gap-1.5 items-center mb-2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <g clipPath='url(#clip0_1505_22277)'>
                    <path
                      d='M13.9944 6.87687L13.281 6.14816L12.392 5.23249L7.86328 9.26523L10.8392 12.1877L14.8834 7.79254L13.9944 6.87687Z'
                      fill='white'
                    />
                    <path
                      d='M16.1717 4.93155C16.4731 5.2444 16.9767 5.25203 17.2896 4.94681L18.8233 3.45886C18.8233 3.45886 19.0828 3.22613 19.2125 3.58476C19.739 4.93536 19.4414 6.52633 18.3388 7.59842C16.8661 9.02914 14.5121 8.9948 13.0814 7.52593C12.6884 7.12151 12.4061 6.65223 12.2344 6.15243L15.1035 3.83275L16.1717 4.93155Z'
                      fill='#CAE7E0'
                    />
                    <path
                      d='M15.7561 4.19883C15.4128 3.84783 15.4204 3.27554 15.7752 2.93598L17.5073 1.24963C17.5073 1.24963 17.7782 0.96349 17.3776 0.803249C15.8668 0.1661 14.0583 0.44843 12.8069 1.66168C11.1397 3.28317 11.1015 5.94622 12.723 7.61349C13.1007 8.00265 13.5395 8.30405 14.0049 8.51389C14.1041 8.56349 14.3483 8.37654 14.3483 8.37654L15.8477 4.86269C15.8477 4.86269 15.9316 4.66429 15.9049 4.47353C15.882 4.33237 15.7523 4.19883 15.7523 4.19883H15.7561Z'
                      fill='white'
                    />
                    <path
                      d='M1.26586 14.3546L7.30924 8.78812C8.35462 7.76944 9.30843 8.5096 10.3233 9.55499L10.5026 9.74193C11.5213 10.7873 12.2309 11.7602 11.1855 12.7751L5.4512 18.6582C4.40582 19.6769 2.71948 19.6502 1.7008 18.6048L1.21245 18.1012C0.193775 17.0558 0.220482 15.3694 1.26586 14.3508V14.3546Z'
                      fill='#CAE7E0'
                    />
                    <path
                      d='M3.66357 17.056C3.46518 17.056 3.26678 16.9797 3.11799 16.8233C2.82421 16.5219 2.83184 16.0373 3.13325 15.7436L3.69791 15.1942C3.99931 14.9004 4.48385 14.908 4.77763 15.2094C5.0714 15.5108 5.06377 15.9954 4.76237 16.2891L4.19771 16.8385C4.04891 16.9835 3.85815 17.056 3.66739 17.056H3.66357Z'
                      fill='#008E6F'
                    />
                    <path
                      d='M12.383 8.34554C12.7412 8.34554 13.0316 8.05515 13.0316 7.69695C13.0316 7.33874 12.7412 7.04835 12.383 7.04835C12.0248 7.04835 11.7344 7.33874 11.7344 7.69695C11.7344 8.05515 12.0248 8.34554 12.383 8.34554Z'
                      fill='#CAE7E0'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_1505_22277'>
                      <rect
                        width='19'
                        height='18.9351'
                        fill='white'
                        transform='translate(0.464844 0.470783)'
                      />
                    </clipPath>
                  </defs>
                </svg>
                <div className='text-[#FFF] text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                  자동차 사고수리
                </div>
              </div>
              <div className='text-[#FFF] text-[18px] font-semibold leading-[27px] tracking-[-0.45px] mb-3'>
                카사요가 엄선한 1급 공업사
              </div>
              <div className='text-white/90 text-[14px] font-normal leading-[22.4px] tracking-[-0.35px]'>
                자동차 수리에서 가장 중요한 것은 경험과 기술입니다. 카사요(주)가
                엄선한 공업사 기준으로 가장 합리적이고 차별화된 서비스를
                받으세요.
              </div>
            </div>
            {/* Footer */}
            <div className='flex justify-between'>
              <div className='flex justify-start items-end flex-auto bg-[#008E6F] rounded-b-[24px] px-6 pb-6 gap-1.5'>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-2 h-2 bg-black/20'></div>
                <div className='rounded-full w-[22px] h-2 bg-[#FFF]'></div>
              </div>
              <div className='relative w-[74px] h-[74px] flex items-end justify-end'>
                <div className='absolute top-0 left-0 w-full h-full'>
                  <InvertedArcCanvas color={'#008E6F'} />
                </div>
                <div
                  className={
                    'cursor-pointer rounded-full z-20 w-[62px] h-[62px] flex items-center justify-center'
                  }
                  onClick={() => {
                    goNext();
                  }}
                >
                  <div className='absolute'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='36'
                      height='36'
                      viewBox='0 0 36 36'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_1495_21901)'>
                        <path
                          d='M7.5 18H28.5'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 27L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M19.5 8.99995L28.5 18'
                          stroke='#222222'
                          strokeWidth='2.4'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_1495_21901'>
                          <rect width='36' height='36' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='62'
                    height='62'
                    viewBox='0 0 62 62'
                    fill='none'
                  >
                    <circle
                      cx='31'
                      cy='31'
                      r='30'
                      fill='white'
                      stroke='url(#paint0_linear_1505_22235)'
                      strokeWidth='2'
                    />
                    <defs>
                      <linearGradient
                        id='paint0_linear_1505_22235'
                        x1='0'
                        y1='0'
                        x2='62'
                        y2='62'
                        gradientUnits='userSpaceOnUse'
                      >
                        <stop stopColor='#7BDDC8' />
                        <stop offset='1' stopColor='#008E6F' />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>

        <div className='h-[50px]'></div>
      </div>
    </div>
  );
}
