import { ChevronDown, ChevronRight } from 'lucide-react';
import { Button_공정거래위원회, Button_한국소비자원 } from './button';
import { motion } from 'framer-motion';

function BottomChevron() {
  return (
    <svg
      width='76'
      height='76'
      viewBox='0 0 76 76'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g filter='url(#filter0_f_224_30421)'>
        <circle cx='38' cy='38' r='18' fill='#E1E5E9' />
      </g>
      <path
        d='M44 35.8L38 41.8L32 35.8'
        stroke='#222222'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <defs>
        <filter
          id='filter0_f_224_30421'
          x='0'
          y='0'
          width='76'
          height='76'
          filterUnits='userSpaceOnUse'
          color-interpolation-filters='sRGB'
        >
          <feFlood flood-opacity='0' result='BackgroundImageFix' />
          <feBlend
            mode='normal'
            in='SourceGraphic'
            in2='BackgroundImageFix'
            result='shape'
          />
          <feGaussianBlur
            stdDeviation='10'
            result='effect1_foregroundBlur_224_30421'
          />
        </filter>
      </defs>
    </svg>
  );
}

/** 소비자 중심 경영(CCM) */
function Section01() {
  return (
    <div className='mt-[50px]'>
      <div className='px-5'>
        <div className='pb-4 text-[#222] text-[20px] font-semibold leading-[30px] tracking-[-0.5px]'>
          소비자 중심 경영(CCM)
        </div>

        <div className='text-[#222] text-[16px] font-normal leading-[25.6px] tracking-[-0.4px] break-keep'>
          기업이 수행하는 모든 활동을 소비자 관점에서 소비자 중심으로 구성하고
          관련 경영 활동을 지속적으로 개선하고 있는지를 한국소비자보호원이
          평가하고 공정거래 위원회가 인증하는 제도입니다.
        </div>

        <div className='grid grid-cols-2 gap-2 mt-5'>
          <Button_공정거래위원회 className='w-full ml-auto max-w-[180px]' />
          <Button_한국소비자원 className='w-full mr-auto max-w-[180px]' />
        </div>
      </div>
    </div>
  );
}

/** CCM 설명 그림 */
function Section02() {
  return (
    <div className='flex flex-col items-center gap-4 mt-[50px] px-4'>
      <img
        width={200}
        height={200}
        alt='설명_1'
        src='/imgs/png/ccm/m_description_1.png'
      />

      <ChevronDown className='w-10 h-10 stroke-[1px]' />

      <img
        width={335}
        height={335}
        alt='설명_2'
        src='/imgs/png/ccm/m_description_2.png'
      />

      <ChevronDown className='w-10 h-10 stroke-[1px]' />

      <img
        width={200}
        height={200}
        alt='설명_3'
        src='/imgs/png/ccm/m_description_3.png'
      />
    </div>
  );
}

/** 설명 표 */
function Section03() {
  return (
    <div className='flex flex-col items-center gap-5 mt-[50px]'>
      {/* 중점 사업 */}
      <div className='w-full h-[70px] flex items-center justify-center border-y border-[#EEE] bg-[#FFF]'>
        <span className='text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
          중점 사업
        </span>
      </div>

      {/* 중점 사업 내용 */}
      <div className='space-y-2.5 w-full px-5'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            자동차 쇼핑 통합 플랫폼
          </span>
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            혁신적인 R&D
          </span>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            소비자 친화적 조직 문화
          </span>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            자동차 금융 서비스
          </span>
        </motion.div>
      </div>

      <div className='flex items-center h-9'>
        <BottomChevron />
      </div>

      {/* 핵심 가치 */}
      <div className='w-full h-[70px] flex items-center justify-center border-y border-[#EEE] bg-[#FFF]'>
        <span className='text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
          핵심 가치
        </span>
      </div>

      {/* 핵심 가치 내용 */}
      <div className='space-y-2.5 w-full px-5'>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            소비자 중심
          </span>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            투명성
          </span>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0, duration: 0.7 },
          }}
          className='flex items-center justify-center h-[70px] bg-[#F8F9FA]'
        >
          <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.4px]'>
            혁신
          </span>
        </motion.div>
      </div>

      <div className='flex items-center h-9'>
        <BottomChevron />
      </div>

      {/* 비전 */}
      <div className='w-full space-y-10'>
        <div className='w-full h-[70px] flex items-center justify-center border-y border-[#EEE] bg-[#FFF]'>
          <span className='text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
            비전
          </span>
        </div>

        <div className='px-5 text-[#222] text-center text-[16px] tiny:text-[15px] tiny:tracking-[-0.7px] tiny:leading-[150%] font-normal leading-[160%] tracking-[-0.4px]'>
          구매 과정의 디지털화, 정보의 투명화,
          <br />
          합리적인 가격구조를 통해 모든 소비자가 신차 구매,
          <br />내 차 팔기, 자동차 금융, 다이렉트 자동차 보험 등<br /> 자동차
          관련 모든 서비스를 이용 시, <br />
          편안함과 만족감을 주는 세상을 만들겠습니다.
        </div>
      </div>

      <div className='flex items-center h-9'>
        <BottomChevron />
      </div>

      {/* 기업전략 */}
      <div className='w-full divide-y-[1px] divide-[#EEE] bg-[#222]'>
        <div className='w-full h-[70px] flex items-center justify-center'>
          <span className='text-white text-[20px] font-semibold leading-[20px] tracking-[-0.5px]'>
            기업전략
          </span>
        </div>

        <div
          style={{
            backgroundImage: 'url("/imgs/png/ccm/m_strategy_flower_x3.png")',
            backgroundSize: '320px, 275px',
          }}
          className='w-full h-[380px] flex flex-col items-center justify-start pt-[38px] bg-bottom bg-no-repeat'
        >
          <div className='text-center'>
            <div className='text-white text-center text-[16px] font-normal leading-[160%] tracking-[-0.4px]'>
              투명하고 체계적인 판매 및 매입 시스템으로
            </div>
            <div className='text-white text-[16px] font-bold leading-[160%] tracking-[-0.4px]'>
              자동차 시장의 깨끗한 유통 거래를 선도
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function MobileCCMPage() {
  return (
    <div className='w-dvw'>
      <div
        style={{
          backgroundImage: 'url("/imgs/png/ccm/m_top_banner_1.png")',
        }}
        className='relative flex flex-col justify-between items-start px-5 pt-[50px] pb-5 bg-top bg-cover gap-6'
      >
        <div className='flex flex-col gap-3.5'>
          <div className='text-[#FFF] text-[24px] tiny:text-[22px] font-bold leading-[36px] tracking-[-0.6px]'>
            카사요(주)는 자동차 업계 최초로
            <br />
            소비자중심경영(CCM) 인증 획득
          </div>
          <div className='text-[#FFF] text-[16px] tiny:text-[14.5px] tiny:tracking-[-0.65px] font-normal leading-[25.6px] tracking-[-0.4px] max-w-[318px] break-keep'>
            고객의 소리에 귀를 기울이고, 고객과의 진정성 있는 소통을 하고
            있습니다. 고객의 효율증대와 권익증진을 목표로 삼고 상생가치를
            추구하며 "고객에게 신뢰받는 기업"이 되겠습니다.
          </div>
        </div>

        <div className='absolute bottom-0 left-0'>
          <img
            src='../imgs/png/ccm/m_person_1.png'
            alt='인증서 이미지'
            // className='w-[296px] h-[436px]'
          />
        </div>

        <div className='relative flex justify-between w-full h-[200px] gap-3.5 tiny:gap-2.5'>
          <div className='flex-auto'>
            <button
              onClick={() => {
                window.open('https://www.joongang.co.kr/article/25298873');
              }}
              className='inline-flex items-center gap-2.5 tiny:gap-1 py-3 tiny:py-2.5 pl-4 pr-2.5 rounded-full bg-white text-[#222] text-[16px] tiny:text-[12px] tiny:pl-2.5 tiny:pr-1.5 tiny:tracking-[-0.7px] font-semibold leading-[16px] tracking-[-0.4px]'
            >
              보도 자료 바로가기
              <ChevronRight
                className='tiny:w-[16px] tiny:h-[16px]'
                color='#222'
                width={20}
                height={20}
                strokeWidth={1.5}
              />
            </button>
          </div>
          <div
            onClick={() => {
              window.open('https://www.joongang.co.kr/article/25298873');
            }}
            className='flex-none shadow-[0px_6.25px_18.75px_#00000026] rounded-[4px]'
          >
            <img
              src='../imgs/png/company/history/ccm_img.png'
              alt='인증서 이미지'
              className='w-[150px] h-[200px] shadow-[0px_0px_0px_2px_#00000066] rounded-[4px]'
            />
          </div>
        </div>
      </div>

      <Section01 />
      <Section02 />
      <Section03 />
    </div>
  );
}
