export const list = [
  {
    title: '국내 최대 네트워크 인프라',
    description: `카 매니저님들과 제휴가 아닌
            26년 현장 경험을 바탕으로 진행합니다.`,
    imgUrl: '../imgs/icons/main_section02_icon01.png',
    point: null,
  },
  {
    title: '저렴한 견적 제공',
    description: `자동차 금융 상품을 직접 취급할 수 있어
            저렴한 견적을 제공할 수 있습니다.`,
    imgUrl: '../imgs/icons/main_section02_icon02.png',
    point: null,
  },
  {
    title: 'ESG 경영',
    description: `환경, 사회, 그리고 지배구조 면에서
            모범적인 기업이 되고자 노력하고 있습니다.`,
    imgUrl: '../imgs/icons/main_section02_icon03.png',
    point: null,
  },
  {
    title: '벤처기업 인증 (혁신 성장형)',
    description: `중소벤처기업부 벤처 인증을
            획득했습니다.`,
    imgUrl: '../imgs/icons/main_section02_icon04.png',
    point: null,
  },
  {
    title: '기업부설 연구소 설립',
    description: `과학기술정보통신부 인증을
            획득했습니다.`,
    imgUrl: '../imgs/icons/main_section02_icon05.png',
    point: null,
  },
  {
    title: '산업재산권(특허) 42개 출원 ',
    description: `지식재산경영 인증, 직무발명보상 우수기업
    특허청 인증을 획득했습니다.`,
    imgUrl: '../imgs/icons/main_section02_icon06.png',
    point: '24년 기준 누적 저작권 102개 ',
  },
];

export const superCarList = [
  {
    title: '롤스로이스',
    imgUrl: '../imgs/png/main_section04_item01.png',
  },
  {
    title: '람보르기니',
    imgUrl: '../imgs/png/main_section04_item02.png',
  },
  {
    title: '벤틀리',
    imgUrl: '../imgs/png/main_section04_item03.png',
  },
  {
    title: '페라리',
    imgUrl: '../imgs/png/main_section04_item04.png',
  },
  {
    title: '롤스로이스',
    imgUrl: '../imgs/png/main_section04_item01.png',
  },
  {
    title: '람보르기니',
    imgUrl: '../imgs/png/main_section04_item02.png',
  },
  {
    title: '벤틀리',
    imgUrl: '../imgs/png/main_section04_item03.png',
  },
  {
    title: '페라리',
    imgUrl: '../imgs/png/main_section04_item04.png',
  },
  {
    title: '롤스로이스',
    imgUrl: '../imgs/png/main_section04_item01.png',
  },
  {
    title: '람보르기니',
    imgUrl: '../imgs/png/main_section04_item02.png',
  },
  {
    title: '벤틀리',
    imgUrl: '../imgs/png/main_section04_item03.png',
  },
  {
    title: '페라리',
    imgUrl: '../imgs/png/main_section04_item04.png',
  },
  {
    title: '롤스로이스',
    imgUrl: '../imgs/png/main_section04_item01.png',
  },
  {
    title: '람보르기니',
    imgUrl: '../imgs/png/main_section04_item02.png',
  },
  {
    title: '벤틀리',
    imgUrl: '../imgs/png/main_section04_item03.png',
  },
  {
    title: '페라리',
    imgUrl: '../imgs/png/main_section04_item04.png',
  },
];

export const slideContents = [
  {
    profile: '../imgs/png/profile/profile01.png',
    rating: 5,
    title: '모든 과정이 빠르고 간단합니다.',
    type: '내 차 팔기',
    nickName: '정민서',
    reviewText: `무엇보다도 가격을 최고가로 받을 수 있는 게 좋은 앱이네요.
      훌륭합니다. 입찰제 중고차 판매가 마음에 듭니다.`,
  },
  {
    profile: '../imgs/png/profile/profile02.png',
    rating: 5,
    title: '편리하게 구매했어요.',
    type: '신차 구매',
    nickName: '태익김',
    reviewText: `새 차 구입할 때 많은 혜택도 받았어요. 추천합니다.
  편리하게 차를 사고파는 게 가능해서 너무 좋아요.`,
  },
  {
    profile: '../imgs/png/profile/profile03.png',
    rating: 5,
    title: '많은 혜택을 받았어요.',
    type: '신차 구매',
    nickName: '람아',
    reviewText: `앱 뛰어납니다. 정말 좋네요. 신차 구입을 했어요.
  많은 혜택으로 정말 만족스러운 구매를 했네요.`,
  },
  {
    profile: '../imgs/png/profile/profile04.png',
    rating: 5,
    title: '인생 첫차를 카사요에서 구입했어요.',
    type: '신차 구매',
    nickName: '브라보마이라이프',
    reviewText: `염려되는 부분에 대한 설명 및 계약 후 인도까지 일사천리로 진행되었네요. 진심과 최선을 다해주신 카 매니저님 감동입니다.`,
  },
  {
    profile: '../imgs/png/profile/profile05.png',
    rating: 5,
    title: '지인에게 추천하고 싶은 앱이에요.',
    type: '내 차 팔기',
    nickName: '로또',
    reviewText: `새 차를 알아보다가 추천으로 앱을 깔고 보니 내 차도 팔게
      되어있어 다 해봤습니다. 둘 다 되니 좋습니다. 감사합니다.`,
  },
  {
    profile: '../imgs/png/profile/profile06.png',
    rating: 5,
    title: '최고가로 판매할 수 있어요.',
    type: '내 차 팔기',
    nickName: '머리카락카리나',
    reviewText: `이용법도 참 쉽고 간편하고 견적 받으면 딜러분들이 입찰해서
      최고가를 맞출 수 있었어요.`,
  },
];

export const serviceLinks = [
  {
    title: '다이렉트 자동차보험',
    img: 'imgs/icons/services/insurance.png',
    description: '최저가로 가입!',
    url: 'https://app.carsayo.net/static/insurance',
  },
  {
    title: '신차 구매',
    img: 'imgs/icons/services/newcar.png',
    description: '원하는 차량의 견적을 한 번에!',
    url: 'https://app.carsayo.net',
  },
  {
    title: '내 차 팔기',
    img: 'imgs/icons/services/sellcar.png',
    description: '감가 없이 안전하게!',
    url: 'https://app.carsayo.net',
  },
  {
    title: '자동차 사고수리',
    img: 'imgs/icons/services/repair.png',
    description: '카사요가 엄선한 1급 공업사',
    url: 'https://app.carsayo.net/static/repairshop',
  },
];
