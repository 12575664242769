import { isBrowser, isMobile } from 'react-device-detect';
import NoticePopup from './components/NoticePopup';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Loader2 } from 'lucide-react';
import { cn } from 'util/index';
import { api } from 'apis';
import { InsuranceCompany } from './components/types';

const HandIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <g clip-path='url(#clip0_1789_23365)'>
        <path
          d='M5.33301 8.66667V3C5.33301 2.73478 5.43836 2.48043 5.6259 2.29289C5.81344 2.10536 6.06779 2 6.33301 2C6.59822 2 6.85258 2.10536 7.04011 2.29289C7.22765 2.48043 7.33301 2.73478 7.33301 3V8'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.33301 7.66732V6.33398C7.33301 6.06877 7.43836 5.81441 7.6259 5.62688C7.81344 5.43934 8.06779 5.33398 8.33301 5.33398C8.59822 5.33398 8.85258 5.43934 9.04011 5.62688C9.22765 5.81441 9.33301 6.06877 9.33301 6.33398V8.00065'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33301 7C9.33301 6.73478 9.43836 6.48043 9.6259 6.29289C9.81344 6.10536 10.0678 6 10.333 6C10.5982 6 10.8526 6.10536 11.0401 6.29289C11.2277 6.48043 11.333 6.73478 11.333 7V8'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11.3335 7.66602C11.3335 7.4008 11.4388 7.14645 11.6263 6.95891C11.8139 6.77137 12.0682 6.66602 12.3335 6.66602C12.5987 6.66602 12.853 6.77137 13.0406 6.95891C13.2281 7.14645 13.3335 7.4008 13.3335 7.66602V10.666C13.3335 11.7269 12.912 12.7443 12.1619 13.4944C11.4117 14.2446 10.3943 14.666 9.33346 14.666H8.00012H8.13879C7.47635 14.6661 6.82425 14.5017 6.24104 14.1875C5.65784 13.8734 5.1618 13.4193 4.79746 12.866L4.66679 12.666C4.45879 12.3467 3.72879 11.074 2.47612 8.84735C2.34842 8.62037 2.31431 8.35253 2.38104 8.10078C2.44778 7.84904 2.61009 7.63327 2.83346 7.49935C3.07137 7.35659 3.35017 7.29743 3.62556 7.33125C3.90095 7.36507 4.15715 7.48993 4.35346 7.68602L5.33346 8.66602'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M3.33366 2.00065L2.66699 1.33398'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.66667 4.66602H2'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M9.33301 2.00065L9.99967 1.33398'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M10 4H10.6667'
          stroke='#0079FC'
          strokeWidth='1.33333'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1789_23365'>
          <rect width='16' height='16' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

const menuInfo = [
  {
    title: '다이렉트 자동차보험',
    desc: '최저가 보험사를 확인하셨다면 아래에서 선택 후 저렴하게 가입하세요.',
    type: 'insurance',
    img: '/imgs/png/service_insurance.png',
  },
  {
    title: '신차 구매',
    desc: '원하는 차량의 견적을 한 번에!',
    type: 'newcar',
    img: '/imgs/png/service_newcar.png',
  },
  {
    title: '내 차 팔기',
    desc: '시세부터 판매까지 감가 없이 안전하게!',
    type: 'sellcar',
    img: '/imgs/png/service_selling.png',
  },
  {
    title: '자동차 사고수리',
    desc: '카사요가 엄선한 1급 공업사',
    type: 'repair',
    img: '/imgs/png/service_repair.png',
  },
];

function BrowserServiceUsage() {
  const searchParams = new URLSearchParams(useLocation().search);
  const queryValue = searchParams.get('param') as
    | 'newcar'
    | 'sellcar'
    | 'insurance'
    | 'repair'
    | undefined;

  const [isLoading, setIsLoading] = useState(true);
  const [insuranceProducts, setInsuranceProducts] = useState<
    InsuranceCompany[]
  >([]);
  const [selectedInsuranceId, setselectedInsuranceIdId] = useState<number>(0); // 선택된 보험사 상품 id

  const initSrc =
    queryValue === 'newcar'
      ? 'https://app.carsayo.net'
      : queryValue === 'sellcar'
        ? 'https://app.carsayo.net'
        : queryValue === 'insurance'
          ? 'https://app.carsayo.net/static/insurance'
          : queryValue === 'repair'
            ? 'https://app.carsayo.net/static/repairshop'
            : 'https://app.carsayo.net';

  const [activeTab, setActiveTab] = useState<
    'newcar' | 'sellcar' | 'insurance' | 'repair' | undefined
  >(queryValue || 'insurance'); // TODO 디폴트 화면

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const navigateIframe = (path: string) => {
    if (iframeRef.current) {
      iframeRef.current.contentWindow?.postMessage(
        { type: 'navigate', path },
        '*'
      );
    }
  };

  // iframe 로딩 완료 시 실행할 함수
  const handleIframeLoad = () => {
    setTimeout(() => {
      if (activeTab === 'newcar') {
        navigateIframe(`/auth/login`);
      }
      if (activeTab === 'sellcar') {
        navigateIframe(`/auth/login`);
      }
      if (activeTab === 'insurance') {
        navigateIframe(`/static/insurance`);
      }
      if (activeTab === 'repair') {
        navigateIframe(`/static/repairshop`);
      }
    }, 500);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  };

  const handleGetInsuranceProduct = async () => {
    try {
      const isDevelopment = searchParams.get('dev') === 'true';
      const targetApiUrl = isDevelopment
        ? 'https://dev.be.carsayo.net/data/insurance/products'
        : 'https://api.carsayo.net/data/insurance/products';

      const res = await api.get(targetApiUrl);

      if (res.status === 200) {
        setInsuranceProducts(res.data);
      }
    } catch (e) {}
  };

  useEffect(() => {
    handleIframeLoad();
  }, [activeTab]);

  useEffect(() => {
    handleGetInsuranceProduct();
  }, []);

  return (
    <div className='w-full flex flex-col'>
      <div className='relative w-[1200px] mx-auto flex justify-between items-center pt-[132px] pb-[40px] flex-none'>
        <div className='flex flex-col gap-4'>
          <div className='font-[700] text-[32px] leading-[48px]'>
            {menuInfo.filter((menu) => menu.type === activeTab)[0].title}
          </div>
          <div className='font-[500] text-[24px] leading-[24px]'>
            {menuInfo.filter((menu) => menu.type === activeTab)[0].desc}
          </div>
        </div>
        <Link
          to='https://insu.coocon.net/bridge.act?org_cd=RUQBBRKU&browser_type=2'
          target='_blank'
          className='relative bg-[#F8F9FA] rounded-[16px] p-5 cursor-pointer hover:bg-gray-100 w-[385px]'
        >
          <div className='flex flex-col gap-[9px]'>
            <div className='text-[#222] text-[15px] font-semibold leading-[150%] tracking-[-0.375px]'>
              다이렉트 자동차보험 7개 보험사
              <br />
              <div className='text-[#0079FC] inline-flex items-center gap-0.5'>
                한 번에 비교하기 <HandIcon />
              </div>
            </div>
            <div className='text-[#999] text-[10px] font-normal leading-[130%] tracking-[-0.2px]'>
              손해보험협회 심의필 제129622호
              <br />
              (2024.08.01 ~ 2025.07.31)
            </div>
          </div>
          <div className='absolute right-3 bottom-3'>
            <img
              className='w-[164px] h-[44px]'
              src='/imgs/icons/services/companyIcons.png'
              alt='보험사'
            />
          </div>
        </Link>
        <div className='absolute top-0 w-full flex items-center justify-center my-5'>
          <ul className='flex gap-[10px] border-[1px] border-[#EEEEEE] rounded-[10px] p-[6px]'>
            {menuInfo
              // .filter((menu) => menu.type !== activeTab)
              .map((menu, index, filteredMenu) => (
                <li
                  key={`service_menu_${menu.type}`}
                  className='flex gap-[10px] cursor-pointer items-center'
                  onClick={() => {
                    setActiveTab(
                      menu.type as 'newcar' | 'sellcar' | 'insurance' | 'repair'
                    );
                    setselectedInsuranceIdId(0);
                    window.location.replace(
                      `${window.location.pathname}?param=${menu.type}`
                    );
                  }}
                >
                  <div
                    className={cn(
                      'flex gap-[6px] items-center font-[600] text-[13px] leading-[13px] py-[10px] px-[8px]',
                      menu.type === activeTab && 'bg-[#F0F3F6] rounded-[8px]'
                    )}
                  >
                    <img alt={menu.title} src={menu.img} className='w-5 h-5' />
                    {menu.title}
                  </div>
                  {index < filteredMenu.length - 1 && (
                    <div className='h-[20px] w-[1px] bg-[#EEEEEE]'></div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </div>
      <div
        className={cn(
          'relative flex-grow flex flex-col items-center justify-center w-full h-full min-w-[1260px]',
          activeTab !== 'insurance' && 'min-h-[1000px]'
        )}
      >
        {activeTab !== 'insurance' ? (
          <div className='flex gap-[250px] items-center py-9'>
            <div className='relative aspect-[340/736] z-[1]'>
              {/* Lodaer */}
              {isLoading && (
                <div className='w-full h-full flex justify-center items-center z-10 absolute rounded-[18.1px] bg-black/25'>
                  <Loader2 className='animate-spin' size={24} />
                </div>
              )}
              <iframe
                ref={iframeRef}
                src={initSrc}
                title='카사요'
                onLoad={handleIframeLoad}
                className='rounded-[18.1px] shadow-[0px_0px_0px_13.6px_#0000001A] bg-[#FFFFFF] aspect-[340/736] h-[736px] z-[1]'
              ></iframe>
            </div>
          </div>
        ) : (
          <div className='w-[1200px] h-full mx-auto gap-[40px]items-center flex flex-col gap-[40px] mb-[100px]'>
            <ul className='flex flex-none px-[17px] py-6 h-fit overflow-x-auto w-full'>
              {insuranceProducts.map((item) => {
                return (
                  <li
                    key={`insurance_product_${item.id}`}
                    onClick={() => setselectedInsuranceIdId(item.id)}
                    className={cn(
                      'flex flex-none cursor-pointer bg-white hover:bg-gray-50 h-fit gap-2.5 mx-[3px] px-4 py-3 rounded-full border-[1.5px] border-[#F8F9FA] flex-shrink-0 justify-center items-center bg-[#F8F9FA]'
                    )}
                    style={{
                      borderColor:
                        selectedInsuranceId === item.id
                          ? item.theme_color
                          : undefined,
                    }}
                  >
                    <img
                      className='w-5 h-5'
                      src={item.logo_url}
                      alt='보험사 로고'
                    />
                    <div className='text-[14px] leading-[14px] font-[500]'>
                      {item.name}
                    </div>
                  </li>
                );
              })}
            </ul>

            {/* 가입 링크가 있는 상품 영역 */}
            <div className='w-full flex-1'>
              <ul className='grid grid-cols-3 gap-[21px]'>
                {insuranceProducts
                  .filter((el) => {
                    return (
                      el.direct.signup_url_web || el.direct.signup_url_mobile
                    );
                  })
                  .map((product) => (
                    <Link
                      key={product.id}
                      to={
                        product.direct.signup_url_web ??
                        product.direct.signup_url_mobile ??
                        ''
                      }
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <li
                        className={cn(
                          'cursor-pointer rounded-[30px] px-[30px] py-[36px] max-w-[386px] min-h-[304px] relative flex flex-col gap-[24px]',
                          product.id === selectedInsuranceId &&
                            'shadow-lg shadow-[#00000033]'
                        )}
                        style={{ backgroundColor: product.theme_bgcolor }}
                      >
                        <div className='flex flex-col gap-[14px]'>
                          <div className='text-[16px] font-medium leading-[16px]'>
                            {product.name}
                          </div>
                          <div className='text-[22px] font-semibold leading-[22px]'>
                            {product.direct.title}
                          </div>
                        </div>

                        <div
                          className={`border-l-[2px] pl-[14px] text-[15px] leading-[24px] font-[400]`}
                          style={{
                            borderColor: product.theme_color,
                          }}
                          dangerouslySetInnerHTML={{
                            __html: product.direct.description,
                          }}
                        ></div>

                        <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
                          AD
                        </div>
                        <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] p-[30px] w-full justify-between flex items-center max-h-[100px]'>
                          <div className='text-[#555555] text-[13px] leading-[19.5px] font-[400] mr-10'>
                            {product.direct.certification}
                          </div>
                          <img
                            className='w-[60px] h-[60px]'
                            alt={product.name}
                            src={product.logo_url}
                          />
                        </div>
                      </li>
                    </Link>
                  ))}
              </ul>
            </div>

            {/* 가입 전화번호가 있는 상품 영역 */}
            {insuranceProducts &&
              insuranceProducts.filter((el) => {
                return el.direct.signup_tel;
              }).length > 0 && (
                <div className='w-full flex-1'>
                  <div className='py-10 bg-gradient-to-r from-[#00BCD8] to-[#00A287] bg-clip-text text-transparent text-[32px] font-bold leading-[150%] tracking-[-0.8px]'>
                    전화로 만나보는 간편한 가입
                  </div>

                  <ul className='grid grid-cols-3 gap-[21px]'>
                    {insuranceProducts
                      .filter((el) => {
                        return el.direct.signup_tel;
                      })
                      .map((product) => {
                        return (
                          <div key={product.id} rel='noopener noreferrer'>
                            <li
                              className={cn(
                                'rounded-[30px] px-[30px] py-[36px] max-w-[386px] h-[386px] relative flex flex-col justify-between gap-[24px] pb-[100px]',
                                product.id === selectedInsuranceId &&
                                  'shadow-lg shadow-[#00000040]'
                              )}
                              style={{ backgroundColor: product.theme_bgcolor }}
                            >
                              <div className='flex flex-col gap-[24px]'>
                                <div className='flex flex-col gap-[14px]'>
                                  <div className='text-[16px] font-medium leading-[16px]'>
                                    {product.name}
                                  </div>
                                  <div className='text-[22px] font-semibold leading-[22px]'>
                                    {product.direct.title}
                                  </div>
                                </div>

                                <div
                                  className={`border-l-[2px] pl-[14px] text-[15px] leading-[24px] font-[400]`}
                                  style={{
                                    borderColor: product.theme_color,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: product.direct.description,
                                  }}
                                ></div>
                              </div>

                              <a
                                href={`tel:${product.direct.signup_tel}`}
                                className='bg-white rounded-full mb-5 py-[19px] flex items-center justify-center gap-1.5 cursor-pointer hover:bg-gray-50'
                              >
                                <svg
                                  xmlns='http://www.w3.org/2000/svg'
                                  width='18'
                                  height='18'
                                  viewBox='0 0 18 18'
                                  fill='none'
                                >
                                  <path
                                    d='M16.479 13.7475C16.479 14.0175 16.419 14.295 16.2915 14.565C16.164 14.835 15.999 15.09 15.7815 15.33C15.414 15.735 15.009 16.0275 14.5515 16.215C14.1015 16.4025 13.614 16.5 13.089 16.5C12.324 16.5 11.5065 16.32 10.644 15.9525C9.78146 15.585 8.91896 15.09 8.06396 14.4675C7.20146 13.8375 6.38396 13.14 5.60396 12.3675C4.83146 11.5875 4.13396 10.77 3.51146 9.915C2.89646 9.06 2.40146 8.205 2.04146 7.3575C1.68146 6.5025 1.50146 5.685 1.50146 4.905C1.50146 4.395 1.59146 3.9075 1.77146 3.4575C1.95146 3 2.23646 2.58 2.63396 2.205C3.11396 1.7325 3.63896 1.5 4.19396 1.5C4.40396 1.5 4.61397 1.545 4.80147 1.635C4.99646 1.725 5.16896 1.86 5.30397 2.055L7.04397 4.5075C7.17897 4.695 7.27646 4.8675 7.34396 5.0325C7.41146 5.19 7.44897 5.3475 7.44897 5.49C7.44897 5.67 7.39646 5.85 7.29146 6.0225C7.19396 6.195 7.05146 6.375 6.87146 6.555L6.30146 7.1475C6.21896 7.23 6.18146 7.3275 6.18146 7.4475C6.18146 7.5075 6.18897 7.56 6.20397 7.62C6.22647 7.68 6.24897 7.725 6.26397 7.77C6.39897 8.0175 6.63146 8.34 6.96146 8.73C7.29896 9.12 7.65896 9.5175 8.04896 9.915C8.45396 10.3125 8.84396 10.68 9.24146 11.0175C9.63146 11.3475 9.95396 11.5725 10.209 11.7075C10.2465 11.7225 10.2915 11.745 10.344 11.7675C10.404 11.79 10.464 11.7975 10.5315 11.7975C10.659 11.7975 10.7565 11.7525 10.839 11.67L11.409 11.1075C11.5965 10.92 11.7765 10.7775 11.949 10.6875C12.1215 10.5825 12.294 10.53 12.4815 10.53C12.624 10.53 12.774 10.56 12.939 10.6275C13.104 10.695 13.2765 10.7925 13.464 10.92L15.9465 12.6825C16.1415 12.8175 16.2765 12.975 16.359 13.1625C16.434 13.35 16.479 13.5375 16.479 13.7475Z'
                                    fill='#222222'
                                    stroke='#222222'
                                    strokeWidth='1.125'
                                    strokeMiterlimit='10'
                                  />
                                </svg>
                                <div className='text-[#222] text-center text-[18px] font-medium leading-[100%] tracking-[-0.36px]'>
                                  {product.direct.signup_tel}
                                </div>
                              </a>

                              <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
                                AD
                              </div>
                              <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] p-[30px] w-full justify-between flex items-center max-h-[100px]'>
                                <div className='text-[#555555] text-[13px] leading-[19.5px] font-[400] mr-10'>
                                  {product.direct.certification}
                                </div>
                                <img
                                  className='w-[60px] h-[60px]'
                                  alt={product.name}
                                  src={product.logo_url}
                                />
                              </div>
                            </li>
                          </div>
                        );
                      })}
                  </ul>
                </div>
              )}
          </div>
        )}

        {activeTab !== 'insurance' && (
          <div className='absolute w-full h-full bg-[#EDF2FD]'>
            {/* <div
              style={{
                backgroundImage: 'url("/imgs/png/service/cloud_bg_1.png")',
              }}
              className='absolute bottom-0 w-full h-full bg-[#EDF2FD] bg-no-repeat bg-cover bg-top'
            ></div>
            <div className='w-full h-full bg-[#A2BFFF] mix-blend-hue'></div>
            <img
              src={'/imgs/png/service/hand_1.png'}
              alt='주요기록이미지'
              className='right-0 top-0 absolute object-cover transition-all max-h-[35%]'
            />
            <img
              src={'/imgs/png/service/hand_2.png'}
              alt='주요기록이미지'
              className='right-0 bottom-0 absolute object-cover transition-all max-h-[50%]'
            />
            <img
              src={'/imgs/png/service/hand_3.png'}
              alt='주요기록이미지'
              className='left-0 bottom-0 absolute object-cover transition-all max-h-[50%]'
            /> */}
          </div>
        )}
      </div>
    </div>
  );
}

function MobileServiceUsage() {
  return (
    <>
      <div className='w-dvw'>
        {/* 카사요 앱 미리보기 영역 */}
        <div className='relative h-[500px] bg-[#EDF2FD]'>
          <div
            style={{
              backgroundImage: 'url("/imgs/png/service/m_cloud_1.png")',
            }}
            className='absolute bottom-0 right-0 w-full h-full bg-[#EDF2FD] bg-no-repeat bg-cover bg-bottom'
          ></div>
          <img
            src={'/imgs/png/service/m_hand_1.png'}
            alt='손_1'
            className='right-0 top-0 absolute object-cover transition-all max-h-[120px]'
          />
          <img
            src={'/imgs/png/service/m_hand_2.png'}
            alt='손_2'
            className='right-0 bottom-0 absolute object-cover transition-all max-h-[230px]'
          />
          <img
            src={'/imgs/png/service/m_hand_3.png'}
            alt='손_3'
            className='left-0 bottom-0 absolute object-cover transition-all max-h-[135px]'
          />
          <div className='absolute bottom-0 w-full flex items-center justify-center'>
            <img
              src={'/imgs/png/service/m_app_preview_1.png'}
              alt='앱 미리보기'
              className='object-cover w-[261px]'
            />
          </div>
        </div>
        {/* 서비스 바로 이용하기 */}
        <div className='bg-white p-5 pb-[50px]'>
          <button
            onClick={() => {
              window.open('https://app.carsayo.net');
            }}
            className='rounded-[10px] bg-[#222222] w-full p-[22px]'
          >
            <span className='text-white font-semibold text-[16px] leading-[16px] tracking-[-0.4px]'>
              서비스 바로 이용하기
            </span>
          </button>
        </div>
      </div>
      <NoticePopup />
    </>
  );
}

export default function ServiceUsage() {
  return (
    <>
      {isBrowser && <BrowserServiceUsage />}
      {isMobile && <MobileServiceUsage />}
    </>
  );
}
