import { serviceLinks } from 'pages/main/main';
import { useNavigate } from 'react-router-dom';
import { cn } from 'util/index';

const items = [
  {
    title: '다이렉트 자동차보험',
    content: '다이렉트 자동차 보험 최저가로 가입!',
    url: serviceLinks.find((el) => {
      return el.title === '다이렉트 자동차보험';
    })?.url,
    className: 'text-white bg-[#3395FF]',
    textColor: '#FFF',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M11.3702 17.9511C10.6773 18.4557 9.73304 18.4557 9.04016 17.9511C6.31942 15.9697 2.29688 12.5744 2.29688 6.71094V5.42919C2.29688 5.00655 2.59869 4.64637 3.01025 4.55021C5.43348 3.98403 7.68383 2.8744 9.60362 1.30185C9.9523 1.01624 10.458 1.01624 10.8067 1.30188C12.7263 2.87465 14.9763 3.98607 17.3993 4.55062C17.8112 4.64661 18.1135 5.00694 18.1135 5.4299V6.71094C18.1135 12.5744 14.0909 15.9697 11.3702 17.9511Z'
          fill='white'
        />
        <path
          d='M7.56641 10.0767L9.39567 11.9059L13.0542 8.24741'
          stroke='#3395FF'
          strokeWidth='1.6'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    title: '신차 구매',
    content: '원하는 차량의 견적을 한 번에!',
    url: serviceLinks.find((el) => {
      return el.title === '신차 구매';
    })?.url,
    className: 'text-[#222] bg-white',
    textColor: '#222',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <path
          d='M5.58209 14.2205C5.58209 13.3122 4.84579 12.5759 3.93753 12.5759C3.02926 12.5759 2.29297 13.3122 2.29297 14.2205V15.8384C2.29297 16.7467 3.02926 17.4829 3.93753 17.4829C4.84579 17.4829 5.58209 16.7467 5.58209 15.8384V14.2205Z'
          fill='#333331'
        />
        <path
          d='M17.3751 14.2205C17.3751 13.3122 16.6388 12.5759 15.7305 12.5759C14.8222 12.5759 14.0859 13.3122 14.0859 14.2205V15.8384C14.0859 16.7467 14.8222 17.4829 15.7305 17.4829C16.6388 17.4829 17.3751 16.7467 17.3751 15.8384V14.2205Z'
          fill='#333331'
        />
        <path
          d='M18.3842 8.79155C18.9096 8.79155 19.3359 8.36519 19.3359 7.83984C19.3359 7.3145 18.9134 6.88813 18.3842 6.88813H17.5391C17.1622 6.88813 16.8424 7.10893 16.6864 7.42489C16.6483 7.36399 16.614 7.29927 16.5798 7.23836C16.3399 6.83103 15.9022 4.78675 15.9022 4.78675C15.9022 4.78675 15.6547 2.12576 12.8186 1.94303C11.7755 1.87831 11.0865 1.86308 9.83784 1.8707C8.58919 1.86308 7.90015 1.8745 6.85708 1.94303C4.02097 2.12195 3.77352 4.78675 3.77352 4.78675C3.77352 4.78675 3.33574 6.83103 3.09591 7.23836C3.06164 7.29927 3.02358 7.36018 2.98931 7.42489C2.83323 7.10893 2.51346 6.88813 2.14039 6.88813H1.29526C0.762305 6.88432 0.335937 7.31069 0.335937 7.83603C0.335937 8.36138 0.758498 8.78775 1.28765 8.78775H1.59981C0.99833 9.43872 0.990716 10.3486 0.990716 10.3486C0.990716 10.3486 0.785146 11.3574 0.994523 13.2951C1.20771 15.2784 3.74307 15.1718 3.74307 15.1718H15.9326C15.9326 15.1718 18.468 15.2822 18.6812 13.2951C18.8905 11.3574 18.685 10.3486 18.685 10.3486C18.685 10.3486 18.6774 9.43872 18.0759 8.78775H18.3842V8.79155Z'
          fill='#4860FF'
        />
        <path
          d='M14.3516 5.81878C14.3516 5.81878 14.0128 3.61461 12.0523 3.52325C11.2719 3.48518 10.7617 3.45853 9.83288 3.46615C8.904 3.45853 8.39008 3.45853 7.61348 3.52325C5.63392 3.68694 5.31414 5.81878 5.31414 5.81878C5.31414 5.81878 5.08954 6.79333 4.92204 7.48999C4.85732 7.76027 5.07812 7.79073 5.07812 7.79073C5.07812 7.79073 5.48545 7.84022 9.88617 7.84022C14.2869 7.84022 14.6105 7.79073 14.6105 7.79073C14.6105 7.79073 14.8122 7.79073 14.7437 7.4976C14.6029 6.90373 14.3516 5.82259 14.3516 5.82259V5.81878Z'
          fill='white'
        />
        <path
          d='M2.63088 12.6101C2.38343 12.6101 2.15121 12.4464 2.08269 12.1952L1.85428 11.3843C1.76672 11.0798 1.94564 10.7638 2.25019 10.68C2.55093 10.5925 2.87071 10.7714 2.95446 11.0759L3.18287 11.8868C3.27043 12.1914 3.0915 12.5073 2.78696 12.5911C2.73366 12.6063 2.68417 12.6139 2.63088 12.6139V12.6101Z'
          fill='#FFDE8B'
        />
        <path
          d='M17.0416 12.61C16.9884 12.61 16.9389 12.6024 16.8856 12.5872C16.581 12.4996 16.4059 12.1875 16.4897 11.8829L16.7181 11.0721C16.8056 10.7675 17.1216 10.5924 17.4223 10.6761C17.7269 10.7637 17.902 11.0759 17.8182 11.3804L17.5898 12.1913C17.5175 12.4425 17.2891 12.6062 17.0416 12.6062V12.61Z'
          fill='#FFDE8B'
        />
        <path
          d='M12.3007 12.3741H7.37465C6.95416 12.3741 6.61328 12.715 6.61328 13.1355V13.2992C6.61328 13.7197 6.95416 14.0606 7.37465 14.0606H12.3007C12.7212 14.0606 13.0621 13.7197 13.0621 13.2992V13.1355C13.0621 12.715 12.7212 12.3741 12.3007 12.3741Z'
          fill='#1938FF'
        />
      </svg>
    ),
  },
  {
    title: '내 차 팔기',
    content: '시세부터 판매까지 감가 없이 안전하게!',
    url: serviceLinks.find((el) => {
      return el.title === '내 차 팔기';
    })?.url,
    className: 'text-white bg-[#7273F9]',
    textColor: '#FFF',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='18'
        height='18'
        viewBox='0 0 18 18'
        fill='none'
      >
        <g clipPath='url(#clip0_1651_8745)'>
          <path
            d='M15.2595 14.087V1.36973C15.2595 0.72235 14.5965 0.286708 14.0023 0.543663L11.9972 1.41074C11.7692 1.50932 11.5107 1.50932 11.2827 1.41074L8.3776 0.154473C8.14965 0.0559019 7.89111 0.055902 7.66316 0.154474L4.75803 1.41074C4.53009 1.50932 4.27154 1.50932 4.0436 1.41074L2.03847 0.543663C1.44426 0.286708 0.78125 0.722349 0.78125 1.36973V16.0435C0.78125 17.1235 1.65777 18 2.73777 18H15.2595V14.087Z'
            fill='white'
          />
          <path
            d='M15.26 18.0002C16.34 18.0002 17.2165 17.1236 17.2165 16.0436C17.2165 14.9636 16.34 14.0871 15.26 14.0871H2.73828C3.81828 14.0871 4.6948 14.9636 4.6948 16.0436C4.6948 17.1236 3.81828 18.0002 2.73828 18.0002H15.26Z'
            fill='#B8B8FF'
          />
          <path
            d='M5.2377 8.88265H4.10683C3.67639 8.88265 3.32422 8.53048 3.32422 8.10004C3.32422 7.66961 3.67639 7.31744 4.10683 7.31744H5.2377C5.66813 7.31744 6.02031 7.66961 6.02031 8.10004C6.02031 8.53048 5.66813 8.88265 5.2377 8.88265Z'
            fill='#7273F9'
          />
          <path
            d='M11.933 8.88265H10.8021C10.3717 8.88265 10.0195 8.53048 10.0195 8.10004C10.0195 7.66961 10.3717 7.31744 10.8021 7.31744H11.933C12.3634 7.31744 12.7156 7.66961 12.7156 8.10004C12.7156 8.53048 12.3634 8.88265 11.933 8.88265Z'
            fill='#7273F9'
          />
          <path
            d='M9.7577 11.6998C9.44074 11.6998 9.15509 11.5081 9.03378 11.2146L8.01639 8.74547L7.00291 11.2146C6.88161 11.512 6.58422 11.7037 6.26335 11.6998C5.94248 11.6959 5.65683 11.4924 5.54335 11.1911L3.80596 6.49547C3.65726 6.08851 3.86465 5.63851 4.2677 5.48982C4.67074 5.34112 5.12465 5.5446 5.27335 5.95156L6.31813 8.76895L7.29639 6.39373C7.4177 6.10025 7.70335 5.90851 8.0203 5.90851C8.33726 5.90851 8.62291 6.10025 8.74422 6.39373L9.72248 8.76895L10.7673 5.95156C10.916 5.5446 11.366 5.33721 11.7729 5.48982C12.1799 5.63851 12.3833 6.08851 12.2347 6.49547L10.4973 11.1911C10.3838 11.4924 10.0981 11.6959 9.77726 11.6998C9.77335 11.6998 9.76943 11.6998 9.76161 11.6998H9.7577Z'
            fill='#7273F9'
          />
        </g>
        <defs>
          <clipPath id='clip0_1651_8745'>
            <rect
              width='16.4348'
              height='18'
              fill='white'
              transform='translate(0.78125)'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: '자동차 사고수리',
    content: '카사요가 엄선한 1급 공업사',
    url: serviceLinks.find((el) => {
      return el.title === '자동차 사고수리';
    })?.url,
    className: 'text-white bg-[#008E6F]',
    textColor: '#FFF',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='20'
        height='20'
        viewBox='0 0 20 20'
        fill='none'
      >
        <g clipPath='url(#clip0_1651_8808)'>
          <path
            d='M13.9944 6.87686L13.281 6.14815L12.392 5.23248L7.86328 9.26521L10.8392 12.1877L14.8834 7.79252L13.9944 6.87686Z'
            fill='white'
          />
          <path
            d='M16.1717 4.93155C16.4731 5.2444 16.9767 5.25203 17.2896 4.94681L18.8233 3.45886C18.8233 3.45886 19.0828 3.22613 19.2125 3.58476C19.739 4.93536 19.4414 6.52633 18.3388 7.59842C16.8661 9.02914 14.5121 8.9948 13.0814 7.52593C12.6884 7.12151 12.4061 6.65223 12.2344 6.15243L15.1035 3.83275L16.1717 4.93155Z'
            fill='#CAE7E0'
          />
          <path
            d='M15.7561 4.19884C15.4128 3.84783 15.4204 3.27554 15.7752 2.93598L17.5073 1.24964C17.5073 1.24964 17.7782 0.963494 17.3776 0.803253C15.8668 0.166104 14.0583 0.448434 12.8069 1.66169C11.1397 3.28317 11.1015 5.94623 12.723 7.61349C13.1007 8.00265 13.5395 8.30406 14.0049 8.5139C14.1041 8.56349 14.3483 8.37655 14.3483 8.37655L15.8477 4.86269C15.8477 4.86269 15.9316 4.6643 15.9049 4.47353C15.882 4.33237 15.7523 4.19884 15.7523 4.19884H15.7561Z'
            fill='white'
          />
          <path
            d='M1.26586 14.3546L7.30924 8.78812C8.35462 7.76944 9.30843 8.5096 10.3233 9.55499L10.5026 9.74193C11.5213 10.7873 12.2309 11.7602 11.1855 12.7751L5.4512 18.6582C4.40582 19.6769 2.71948 19.6502 1.7008 18.6048L1.21245 18.1012C0.193775 17.0558 0.220482 15.3694 1.26586 14.3508V14.3546Z'
            fill='#CAE7E0'
          />
          <path
            d='M3.66357 17.056C3.46518 17.056 3.26678 16.9797 3.11799 16.8233C2.82421 16.5219 2.83184 16.0373 3.13325 15.7436L3.69791 15.1942C3.99931 14.9004 4.48385 14.908 4.77763 15.2094C5.0714 15.5108 5.06377 15.9954 4.76237 16.2891L4.19771 16.8385C4.04891 16.9835 3.85815 17.056 3.66739 17.056H3.66357Z'
            fill='#008E6F'
          />
          <path
            d='M12.383 8.34554C12.7412 8.34554 13.0316 8.05516 13.0316 7.69695C13.0316 7.33874 12.7412 7.04836 12.383 7.04836C12.0248 7.04836 11.7344 7.33874 11.7344 7.69695C11.7344 8.05516 12.0248 8.34554 12.383 8.34554Z'
            fill='#CAE7E0'
          />
        </g>
        <defs>
          <clipPath id='clip0_1651_8808'>
            <rect
              width='19'
              height='18.9351'
              fill='white'
              transform='translate(0.464844 0.470779)'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

const Icon = ({ color }: { color: string }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
  >
    <g clipPath='url(#clip0_1651_9330)'>
      <path
        d='M16.75 6.65093L6.85051 16.5504'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16.75 15.1362V6.65093'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M8.26127 6.65148H16.7465'
        stroke={color}
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_1651_9330'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export function MobileGate() {
  return (
    <div className='w-dvw py-[50px] space-y-[30px] px-5 bg-[#EDF2FD] flex-auto'>
      {/* 상단 텍스트 영역 */}
      <div className='flex flex-col items-center justify-center space-y-3'>
        <div className='text-[#6E77F6] text-[14px] font-semibold leading-[14px] tracking-[-0.35px]'>
          자동차에 필요한 모든 답
        </div>
        <div className='text-[#222] text-[24px] font-semibold leading-[36px] tracking-[-0.6px]'>
          서비스 바로가기
        </div>
      </div>

      <div className='flex flex-col gap-4'>
        {items.map((el, index) => {
          return (
            <div
              key={index}
              className={cn(
                'w-full rounded-[24px] px-5 py-[30px] relative cursor-pointer',
                el.className
              )}
              onClick={() => {
                if (el.url) window.location.href = el.url;
              }}
            >
              <div className='absolute top-4 right-4'>
                <Icon color={el.textColor} />
              </div>
              <div className='flex flex-col items-center justify-between gap-2'>
                <div className='flex gap-1.5 items-center justify-start w-full'>
                  <div>{el.svg}</div>
                  <div className='text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                    {el.title}
                  </div>
                </div>
                <div className='w-full text-[18px] tiny:text-[16px] font-semibold leading-[27px] tracking-[-0.45px]'>
                  {el.content}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
