import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';

export default function MServiceGo() {
  return (
    <>
      <motion.section
        initial={{ opacity: 0.7 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='relative w-[100dvw] bg-[#E2F0FF] pt-10 pb-[20px]'
      >
        <div className='absolute w-[100px] h-[100px] right-5 top-5'>
          <img
            className='w-[100px] h-[100px]'
            src='imgs/icons/main/insuBannerImg.png'
            alt='car'
          />
        </div>
        <div className='px-[20px]'>
          <Link
            to={'https://app.carsayo.net/static/insurance'}
            className='relative'
          >
            <div className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.45px]'>
              다이렉트 자동차보험
            </div>
            <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px] pt-1 pb-6'>
              보험사별로 금액 비교하고 <br />
              직접 가입하면 더욱 저렴합니다.
            </div>
            <div className='rounded-full bg-[#0079FC] flex items-center justify-center w-full h-[44px]'>
              <span className='text-[14px] font-semibold leading-[150%] tracking-[-0.35px] text-white'>
                내 보험료 확인하기
              </span>
            </div>
          </Link>
        </div>

        <div className='pt-6'>
          <Swiper
            spaceBetween={10}
            slidesPerView={1.1}
            centeredSlides={true}
            navigation
            pagination={{ clickable: true }}
            className='h-[354px]'
          >
            <SwiperSlide className='h-[354px] bg-white'>
              <div className='relative flex items-center justify-start flex-col h-full'>
                <div className='inline-flex items-center gap-2 text-[#222] font-semibold text-[16px] leading-[24px] tracking-[-0.4px] border-b-[1px] border-[#D6EAFF] py-[16px] w-[calc(100%-40px)] tiny:text-[14px]'>
                  <span className='text-[#0079FC]'>STEP 1</span>나에게 맞는
                  최저가 보험사 확인 후
                </div>
                <div className='mt-[30px] text-[#222] text-center text-[14px] font-normal leading-[21px] tracking-[-0.35px]'>
                  <div>가장 저렴한 보험사를</div>
                  <div className='inline-flex'>
                    <div className='relative text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px]'>
                      <div className='absolute bottom-0 bg-[#D6EAFF] w-full h-[11px]'></div>
                      <div className='relative'>비교 후 확인</div>
                    </div>
                    하세요.
                  </div>
                </div>

                <div className='absolute bottom-0'>
                  <img
                    className='w-[198px] h-[204px]'
                    src='imgs/icons/main/phoneEx1.png'
                    alt='car'
                  />
                </div>
                <div className='absolute bottom-2.5 right-2.5 rounded-full bg-black/50 flex items-center justify-center text-white font-medium text-[13px] leading-[13px] tracking-[-0.26px] w-[44px] h-[24px]'>
                  1/2
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='h-[354px] bg-white'>
              <div className='relative flex items-center justify-start flex-col h-full'>
                <div className='inline-flex items-center gap-2 text-[#222] font-semibold text-[16px] leading-[24px] tracking-[-0.4px] border-b-[1px] border-[#D6EAFF] py-[16px] w-[calc(100%-40px)] tiny:text-[14px]'>
                  <span className='text-[#0079FC]'>STEP 2</span>직접 가입하기
                </div>
                <div className='mt-[30px] text-[#222] text-center text-[14px] font-normal leading-[21px] tracking-[-0.35px]'>
                  <div className='inline-flex'>
                    원하는 보험사로
                    <div className='relative text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] ml-[2px]'>
                      <div className='absolute bottom-0 bg-[#D6EAFF] w-full h-[11px]'></div>
                      <div className='relative'>직접 가입</div>
                    </div>
                    하면
                  </div>
                  <div>더 알뜰하게, 저렴하게 가입합니다.</div>
                </div>

                <div className='absolute bottom-0'>
                  <img
                    className='w-[198px] h-[204px]'
                    src='imgs/icons/main/phoneEx2.png'
                    alt='car'
                  />
                </div>
              </div>
              <div className='absolute bottom-2.5 right-2.5 rounded-full bg-black/50 flex items-center justify-center text-white font-medium text-[13px] leading-[13px] tracking-[-0.26px] w-[44px] h-[24px]'>
                2/2
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </motion.section>

      <motion.section
        initial={{ opacity: 0 }}
        whileInView={{
          opacity: 1,
          transition: { delay: 0, duration: 0.7 },
        }}
        className='relative w-[100dvw] px-[20px] bg-white pt-5'
      >
        <div className='absolute w-[100px] h-[100px] right-5 top-5'>
          <img
            className='w-[100px] h-[100px]'
            src='imgs/icons/main/insuBannerImg.png'
            alt='car'
          />
        </div>

        <div className='flex-auto flex flex-col gap-5'>
          <Link
            to={'https://app.carsayo.net'}
            className='bg-[#F8F9FA] flex-auto relative'
          >
            <div className='flex flex-col h-[176px] justify-between pt-[30px] pb-[23px] px-[24px]'>
              <div className='flex flex-col gap-1'>
                <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                  신차 구매
                </div>
                <div className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.45px]'>
                  원하는 차량의 견적을 한 번에!
                </div>
              </div>
              <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
                서비스 이용하기
              </div>
            </div>
            <div className='absolute right-2.5 bottom-2.5 flex items-center justify-center'>
              <img
                className='w-[80px] h-[80px]'
                src='imgs/icons/main/note.png'
                alt='car'
              />
            </div>
          </Link>
          <Link
            to={'https://app.carsayo.net'}
            className='bg-[#EDF1FF] flex-auto relative'
          >
            <div className='flex flex-col h-[176px] justify-between pt-[30px] pb-[23px] px-[24px]'>
              <div className='flex flex-col gap-1'>
                <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                  내 차 팔기
                </div>
                <div className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.45px]'>
                  시세부터 판매까지 감가 없이 안전하게!
                </div>
              </div>
              <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
                서비스 이용하기
              </div>
            </div>
            <div className='absolute right-2.5 bottom-2.5 flex items-center justify-center'>
              <img
                className='w-[80px] h-[80px]'
                src='imgs/icons/main/phone.png'
                alt='car'
              />
            </div>
          </Link>
          <Link
            to={'https://app.carsayo.net/static/repairshop'}
            className='bg-[#E4F7F3] flex-auto relative'
          >
            <div className='flex flex-col h-[176px] justify-between pt-[30px] pb-[23px] px-[24px]'>
              <div className='flex flex-col gap-1'>
                <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                  자동차 사고수리
                </div>
                <div className='text-[#222] font-semibold text-[18px] leading-[27px] tracking-[-0.45px]'>
                  카사요가 엄선한 1급 공업사
                </div>
              </div>
              <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
                서비스 이용하기
              </div>
            </div>
            <div className='absolute right-2.5 bottom-2.5 flex items-center justify-center'>
              <img
                className='w-[80px] h-[80px]'
                src='imgs/icons/main/hammer.png'
                alt='car'
              />
            </div>
          </Link>
        </div>
      </motion.section>
    </>
  );
}
