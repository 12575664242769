import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cn } from '../../util';
import CarsayoLogo from '../../common/icons/logo';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isBrowser, isMobile } from 'react-device-detect';
import { LinkUrl } from 'common/data/links';
import TopBanner, { useTopBannerStore } from 'components/TopBanner';
import { serviceLinks } from 'pages/main/main';

const audioFiles = [
  '../audio/carsayoA.wav',
  '../audio/carsayoB.wav',
  '../audio/carsayoC.wav',
  '../audio/carsayoD.wav',
];

export const HeaderMenus: {
  title: string;
  path: string;

  subMenu: {
    title: string;

    subPages: {
      title: string;
      path: string;
    }[];

    description: {
      title: string;
      content: string;
      banner?: React.ReactNode;
    } | null;
  } | null;
}[] = [
  {
    title: '회사 소개',
    path: '/company',
    subMenu: {
      title: 'Innovation of CARSAYO',
      subPages: [
        {
          title: 'CEO 메시지',
          path: '/company/ceo',
        },
        {
          title: '기업 개요',
          path: '/company/overview',
        },
        {
          title: '경영 이념',
          path: '/company/philosophy',
        },
        {
          title: 'CI / BI',
          path: '/company/ci',
        },
        {
          title: 'Character Story',
          path: '/company/character',
        },
      ],
      description: null,
    },
  },
  {
    title: 'ESG 경영',
    path: '/esg',
    subMenu: {
      title: 'ESG of CARSAYO',
      subPages: [],
      description: {
        title: '카사요(주)의 ESG 방향성',
        content:
          '카사요(주)가 제공하는 자동차 쇼핑 통합 플랫폼과 서비스를 포함한 모든 경영활동은 고객과 더불어, 더 나아가 사회와 환경의 지속 가능성을 실천해 나가며 모범적인 기업이 되고자 노력하고 있습니다.',
        banner: (
          <Link
            to={'/esg'}
            style={{
              backgroundImage: "url('../imgs/png/banner_tabbar_1_x3.png')",
            }}
            className='w-full h-[80px] bg-cover bg-right flex items-center justify-start px-6 select-none'
          >
            <span className='text-[#222222] text-[14px] font-normal leading-[100%] tracking-[-0.35px]'>
              Life’s Happy with CARSAYO
            </span>
          </Link>
        ),
      },
    },
  },
  {
    title: '소비자 중심 경영',
    path: '/ccm',
    subMenu: {
      title: 'CCM of CARSAYO',
      subPages: [],
      description: {
        title: '카사요(주), 소비자를 먼저 생각하겠습니다',
        content:
          '고객의 피드백을 적극적으로 수용하고 이를 바탕으로 제품과 서비스를 개선하여 소비자와의 신뢰를 통해 지속 가능한 가치를 창출합니다.',
        banner: (
          <Link
            to={'/ccm'}
            style={{
              backgroundImage: "url('../imgs/png/banner_tabbar_2_x3.png')",
            }}
            className='w-full h-[80px] bg-cover bg-right flex items-center justify-start px-6'
          >
            <span className='text-[#222222] text-[14px] font-normal leading-[100%] tracking-[-0.35px]'>
              Where Customers Lead, We Follow
            </span>
          </Link>
        ),
      },
    },
  },
  {
    title: '미디어 스토리',
    path: '/media',
    subMenu: null,
  },
  {
    title: '프리미엄 혜택',
    path: '/premium',
    subMenu: null,
  },
  {
    title: '서비스 바로가기',
    path: '/service',
    subMenu: null,
  },
];

function SubMenus() {
  const location = useLocation();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const mainPageElement = document.getElementById(
      'main'
    ) as HTMLElement | null;

    const handleScroll = () => {
      if (mainPageElement) {
        setIsSticky(mainPageElement.scrollTop > 170);
      }
    };

    if (mainPageElement) {
      mainPageElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (mainPageElement) {
        mainPageElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const currentSubMenus = useMemo(() => {
    for (let i = 0; i < HeaderMenus.length; i++) {
      if (!HeaderMenus[i]?.subMenu?.subPages) continue;

      if (location.pathname.startsWith(HeaderMenus[i].path))
        return HeaderMenus[i].subMenu?.subPages ?? [];
    }

    return [];
  }, [location]);

  // 특수한 케이스에 대응합니다.
  const special_className = useMemo(() => {
    // 캐릭터 페이지에서 배경 색 필요
    if (location.pathname === '/company/character') return 'bg-[#E5E6FF]';
    else return null;
  }, [location]);

  if (currentSubMenus.length === 0) return null;

  return (
    <div
      className={cn(
        'w-full min-w-[1260px] pt-[60px]',
        special_className && special_className
      )}
    >
      <div>
        <h3 className='mb-[30px] text-center text-[32px] leading-[32px] font-[500]'>
          회사소개
        </h3>
        <div
          id='SubMenuTab'
          className={cn(
            `tab-menu transition-colors duration-[3000] ${isSticky ? `fixed inline-flex justify-center items-center left-[50%] -translate-x-[50%] z-[5] top-[100px]` : 'w-full flex justify-center items-center'}`
          )}
        >
          <ul
            className={`inline-flex justify-center items-center gap-[10px] p-[10px] rounded-[10px] transition-all duration-300 ${isSticky && 'opacity-90 bg-[#EEF2F5] backdrop-blur-[15px]'}`}
          >
            {currentSubMenus.map((el) => {
              return (
                <Link
                  to={el.path}
                  key={el.path}
                  className={cn(
                    'py-[12px] px-[14px] rounded-[6px] bg-transparent text-[16px] leading-[16px] cursor-pointer font-[500] transition-all duration-300 hover:bg-[rgba(34,34,34,0.08)] hover:text-black',
                    location.pathname.startsWith(el.path) &&
                      'bg-[#222222] text-white'
                  )}
                >
                  {el.title}
                </Link>
              );
            })}
          </ul>
        </div>
        <div className={isSticky ? 'block w-full h-[60px]' : 'hidden'}></div>
      </div>
    </div>
  );
}

function BrowserHeader() {
  const location = useLocation();

  const [hoveredMenuIndex, setHoveredMenuIndex] = useState<number | null>(null);

  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);

  const [isVideoPlay, setVideoPlay] = useState<boolean | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  /** 오디오 켰다껐다 */
  const playRandomAudio = () => {
    if (videoRef.current && isVideoPlay) {
      videoRef.current.pause();
      setVideoPlay(false);
    }

    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current
          .play()
          .then(() => {
            setAudioPlay(true);
          })
          .catch((error) => {
            console.error('오디오 재생 중 오류 발생:', error);
          });
      }
    }
  };
  /** 비디오 켰다껐다 */
  const handleClickEvents = {
    videoPlay: () => {
      if (audioRef.current && audioPlay) {
        audioRef.current.pause();
        setAudioPlay(false);
      }

      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setVideoPlay(true);
        } else {
          videoRef.current.pause();
          setVideoPlay(false);
        }
      }
    },
  };

  useEffect(() => {
    if (hoveredMenuIndex === null) setVideoPlay(null);
  }, [hoveredMenuIndex]);

  useEffect(() => {
    if (hoveredMenuIndex !== null) setHoveredMenuIndex(null);
  }, [location.pathname]);

  return (
    <>
      <header
        className={cn(
          'flex-none w-full min-w-[1260px] sticky top-0 left-0 border-b border-[#EEEEEE] bg-white z-10'
        )}
      >
        <div className='w-[1200px] mx-auto flex justify-between items-center'>
          {location.pathname === '/' ? (
            <h1
              className='p-4 shrink-0 cursor-pointer'
              onClick={() => {
                window.location.reload();
                window.scrollTo({ top: 0 });
              }}
            >
              <CarsayoLogo width='120' />
            </h1>
          ) : (
            <Link to='/' className='p-4 shrink-0'>
              <CarsayoLogo width='120' />
            </Link>
          )}
          <div className='flex justify-center items-center flex-1'>
            <nav
              className='flex-1'
              onMouseLeave={() => {
                setHoveredMenuIndex(null);
              }}
            >
              <ul className='flex justify-center items-center text-[16px] leading-[16px] font-[500]'>
                {HeaderMenus.map((el, index) => {
                  return (
                    <li
                      key={el.path}
                      onClick={() => {
                        setHoveredMenuIndex(null);
                      }}
                      onMouseEnter={() => {
                        if (el.subMenu) return setHoveredMenuIndex(index);
                        else setHoveredMenuIndex(null);
                      }}
                      className='inline-block'
                    >
                      <Link
                        to={
                          el.subMenu?.subPages?.[0]
                            ? el.subMenu.subPages[0].path
                            : el.path
                        }
                        className={cn(
                          'px-5 flex justify-center items-center h-[80px] text-[18px] leading-[24px] hover:text-[#999999] transition-all',
                          location.pathname.startsWith(el.path) &&
                            'border-b-[2px] border-[#222222] font-[700]',
                          el.title === '서비스 바로가기' &&
                            'text-[#494AF1] border-[#494AF1] hover:text-[#4949f1a3]'
                        )}
                      >
                        {el.title}
                      </Link>
                    </li>
                  );
                })}
              </ul>
              <AnimatePresence mode='sync'>
                {hoveredMenuIndex !== null &&
                  HeaderMenus[hoveredMenuIndex].subMenu !== null && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.3 }}
                      className={cn(
                        'absolute left-0 w-full border-b border-[#EEE] bg-[rgba(255,255,255,0.70)] backdrop-blur-[50px]',
                        'top-[80px]'
                      )}
                    >
                      <div className='max-w-[1200px] mx-auto py-[40px] flex justify-between items-stretch gap-20 h-[340px]'>
                        {/* 좌측 */}
                        <div className='flex flex-col justify-between min-w-[250px] h-[260px]'>
                          <div className='flex flex-col gap-3.5'>
                            <div className='text-[#222222] text-[14px] font-light leading-[100%] tracking-[4.2px]'>
                              {HeaderMenus[hoveredMenuIndex].subMenu?.title}
                            </div>

                            {HeaderMenus[hoveredMenuIndex].subMenu
                              ?.description && (
                              <Link
                                to={HeaderMenus[hoveredMenuIndex].path}
                                className='space-y-5'
                              >
                                <div className='text-[#555] text-[16px] font-bold leading-[16px] tracking-[-0.4px] mt-5'>
                                  {
                                    HeaderMenus[hoveredMenuIndex].subMenu
                                      ?.description?.title
                                  }
                                </div>
                                <div className='text-[#222222] break-keep text-[15px] font-medium leading-[160%] tracking-[-0.375px]'>
                                  {
                                    HeaderMenus[hoveredMenuIndex].subMenu
                                      ?.description?.content
                                  }
                                </div>
                              </Link>
                            )}

                            <ul className='flex flex-col justify-start items-start w-full'>
                              {HeaderMenus[
                                hoveredMenuIndex
                              ].subMenu?.subPages.map((el) => {
                                return (
                                  <Link
                                    to={el.path}
                                    onClick={() => {
                                      setHoveredMenuIndex(null);
                                    }}
                                    className='flex justify-start items-center w-full h-full py-4 text-[16px] leading-[16px] font-[700] hover:text-[#999] transition-colors duration-300'
                                  >
                                    {el.title}
                                  </Link>
                                );
                              })}
                            </ul>
                          </div>

                          {HeaderMenus[hoveredMenuIndex].subMenu?.description
                            ?.banner && (
                            <>
                              {HeaderMenus[hoveredMenuIndex].subMenu
                                ?.description?.banner ?? null}
                            </>
                          )}
                        </div>
                        {/* 우측 */}
                        <div className='flex justify-center items-start gap-4'>
                          <div className='border border-[#EEEEEE]'>
                            <div
                              onClick={handleClickEvents.videoPlay}
                              className='relative w-[320px] h-[180px] group cursor-pointer select-none'
                            >
                              {/* Thumbnale */}
                              <img
                                src='../imgs/png/video_bg.png'
                                alt='재생 이미지'
                                className={cn(
                                  'w-full absolute z-[1]',
                                  isVideoPlay !== null && 'hidden'
                                )}
                              />
                              <div
                                className={cn(
                                  'absolute w-full h-full flex justify-center items-center z-[1]',
                                  isVideoPlay === true
                                    ? 'bg-transparent'
                                    : 'bg-[#0000001A]'
                                )}
                              >
                                {isVideoPlay === true ? (
                                  <span className='w-[50px] h-[50px] opacity-0 transition-all flex justify-center items-center group-hover:opacity-100'>
                                    <img
                                      src='../imgs/icons/stop.png'
                                      alt='일시정지 이미지'
                                      className='w-full object-contain'
                                    />
                                  </span>
                                ) : isVideoPlay === null ? (
                                  <span className='w-[50px] h-[50px] flex justify-center items-center'>
                                    <img
                                      src='../imgs/icons/play.png'
                                      alt='재생 이미지'
                                      className='w-full object-contain'
                                    />
                                  </span>
                                ) : (
                                  <span className='w-[50px] h-[50px] flex justify-center items-center'>
                                    <img
                                      src='../imgs/icons/play.png'
                                      alt='재생 이미지'
                                      className='w-full object-contain'
                                    />
                                  </span>
                                )}
                              </div>

                              <div className='w-full h-full overflow-hidden flex items-center justify-center'>
                                <video
                                  className='w-[calc(100%+8px)] h-[calc(100%+8px)] -m-[4px] object-cover cursor-pointer'
                                  webkit-playsinline='true'
                                  playsInline
                                  preload='auto'
                                  loop={true}
                                  ref={videoRef}
                                >
                                  <source
                                    src='../videos/carsayo.mp4'
                                    type='video/mp4'
                                  />
                                </video>
                              </div>
                            </div>
                            <div className='flex flex-col justify-start items-start gap-2.5 py-[20px] px-4 bg-[#FFFFFFCC] border-t border-[#EEEEEE]'>
                              <span className='text-[14px] leading-[14px]'>
                                BRAND FILM
                              </span>
                              <strong className='text-[16px] leading-[16px] font-[600]'>
                                카사요가 만든 자동차 문화
                              </strong>
                            </div>
                          </div>
                          <div className='border border-[#EEEEEE]'>
                            <div
                              onClick={playRandomAudio}
                              style={{
                                backgroundImage:
                                  "url('../imgs/png/audio_bg.png')",
                              }}
                              className={cn(
                                'relative cursor-pointer w-[320px] h-[180px] flex justify-center items-center bg-no-repeat bg-cover bg-center bg-blend-multiply',
                                audioPlay === true
                                  ? 'bg-transparent'
                                  : 'bg-[#0000001A]'
                              )}
                            >
                              <button>
                                <div className='flex flex-col justify-center items-center gap-8'>
                                  {audioPlay ? (
                                    <span className='w-[50px] h-[50px]'>
                                      <img
                                        src='../imgs/icons/stop.png'
                                        alt='정지 아이콘'
                                        className='w-full object-contain'
                                      />
                                    </span>
                                  ) : (
                                    <span className='flex justify-center items-center w-[50px] h-[50px]'>
                                      <img
                                        src='../imgs/icons/play.png'
                                        alt='오디오 재생 아이콘'
                                        className='w-full object-contain'
                                      />
                                    </span>
                                  )}
                                  <ul
                                    className={`audio-visualizer flex justify-center items-center transition-all ${
                                      audioPlay
                                        ? 'playing opacity-100 absolute bottom-[20px]'
                                        : 'opacity-0 absolute bottom-[0px] z-[-999]'
                                    }`}
                                  >
                                    {/* 이거 있어야 이퀄라이저 돌아감!! */}
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                  </ul>
                                </div>

                                <audio ref={audioRef} loop />
                              </button>
                            </div>
                            <div className='flex flex-col justify-start items-start gap-2.5 py-[20px] px-4 bg-[#FFFFFFCC]  border-t border-[#EEEEEE]'>
                              <span className='text-[14px] leading-[14px]'>
                                BRAND AUDIO
                              </span>
                              <strong className='text-[16px] leading-[16px] font-[600]'>
                                카사요가 만든 나레이션(feat.배기성)
                              </strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </motion.div>
                  )}
              </AnimatePresence>
            </nav>
            <div className='flex justify-center items-center gap-[20px]'>
              <div className='flex justify-center items-center gap-4 py-[15px] pl-[20px] border-l border-[#EEEEEE]'>
                <Link
                  to={LinkUrl.소비자중심경영소개}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='w-[49px] h-[49px] flex justify-center items-center'
                >
                  <img
                    src='/imgs/png/ccm_mark.png'
                    alt='소비자중심경영인증마크'
                    className='w-full object-contain'
                  />
                </Link>
                <Link
                  to={LinkUrl.가족친화지원사업}
                  target='_blank'
                  className='w-[49px] h-[49px] flex justify-center items-center'
                >
                  <img
                    src='/imgs/png/family_friendly_mark.png'
                    alt='가족친화인증마크'
                    className='w-full object-contain'
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>

      <SubMenus />
    </>
  );
}

function MobileSubMenus() {
  const navigate = useNavigate();
  const location = useLocation();

  const { isTopBannerOpen } = useTopBannerStore();

  const audioRef = useRef<HTMLAudioElement>(null);
  const [audioPlay, setAudioPlay] = useState<boolean | null>(null);

  const [isVideoPlay, setVideoPlay] = useState<boolean | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  /** 오디오 켰다껐다 */
  const playRandomAudio = () => {
    if (videoRef.current && isVideoPlay) {
      videoRef.current.pause();
      setVideoPlay(false);
    }

    if (audioPlay) {
      if (audioRef.current) {
        audioRef.current.pause();
      }
      setAudioPlay(null);
    } else {
      const randomIndex = Math.floor(Math.random() * audioFiles.length);
      if (audioRef.current) {
        audioRef.current.src = audioFiles[randomIndex];
        audioRef.current
          .play()
          .then(() => {
            setAudioPlay(true);
          })
          .catch((error) => {
            console.error('오디오 재생 중 오류 발생:', error);
          });
      }
    }
  };
  /** 비디오 켰다껐다 */
  const handleClickEvents = {
    videoPlay: () => {
      if (audioRef.current && audioPlay) {
        audioRef.current.pause();
        setAudioPlay(false);
      }

      if (videoRef.current) {
        if (videoRef.current.paused) {
          videoRef.current.play();
          setVideoPlay(true);
        } else {
          videoRef.current.pause();
          setVideoPlay(false);
        }
      }
    },
  };

  return (
    <motion.div
      initial={{ height: 0 }}
      animate={{ height: 'auto' }}
      exit={{ height: 0 }}
      transition={{ duration: 0.5, ease: 'easeInOut' }}
      className={cn(
        'overflow-y-scroll w-dvw max-h-[calc(100dvh-60px)] z-10 absolute px-5 border-y-[1px] border-[#EEE] bg-[rgba(255,255,255,0.70)] backdrop-blur-[50px]',
        isTopBannerOpen
          ? 'max-h-[calc(100dvh-60px)]'
          : 'max-h-[calc(100dvh-60px)]'
      )}
    >
      <div className='text-[#222] font-light text-[14px] leading-[14px] tracking-[4.2px] pb-5 mt-[30px]'>
        Innovation of CARSAYO
      </div>
      {HeaderMenus.map((el) => {
        return (
          <>
            <Link
              to={
                el.subMenu?.subPages?.[0]
                  ? el.subMenu.subPages[0].path
                  : el.path
              }
              key={el.path}
              className={cn(
                'text-[#555] font-bold text-[16px] leading-[16px] tracking-[-0.4px] h-[60px] flex items-center',
                location.pathname.startsWith(el.path) && 'text-[#999]',
                el.title === '서비스 바로가기' &&
                  'text-[#494AF1] pointer-events-none'
              )}
            >
              {el.title}
            </Link>
          </>
        );
      })}

      <div className='grid grid-cols-2 gap-[7px]'>
        {serviceLinks.map((el) => {
          return (
            <div className='h-[110px] flex flex-col justify-center items-center bg-[rgba(4,19,34,0.05)]'>
              <div
                className='flex flex-col gap-[13px] justify-center items-center'
                onClick={() => {
                  window.location.href = el.url;
                }}
              >
                <div className='bg-white rounded-full w-[50px] h-[50px] flex justify-center items-center'>
                  <img
                    className='w-[30px] h-[30px]'
                    src={el.img}
                    alt={el.title}
                  />
                </div>
                <div className='text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.375px]'>
                  {el.title}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {/* 비디오 - 나레이션 영역 */}
      <div className='py-5 space-y-5 mb-[90px]'>
        <div className=''>
          <div
            onClick={handleClickEvents.videoPlay}
            className='relative w-full aspect-[320/180] cursor-pointer select-none'
          >
            {/* Thumbnale */}
            <img
              src='../imgs/png/video_bg.png'
              alt='재생 이미지'
              className={cn(
                'w-full absolute z-[1]',
                isVideoPlay !== null && 'hidden'
              )}
            />
            <div
              className={cn(
                'absolute w-full h-full flex justify-center items-center z-[1]',
                isVideoPlay === true ? 'bg-transparent' : 'bg-[#0000001A]'
              )}
            >
              {isVideoPlay === true ? (
                <span className='w-[50px] h-[50px] opacity-0 transition-all flex justify-center items-center group-hover:opacity-100'>
                  <img
                    src='../imgs/icons/stop.png'
                    alt='일시정지 이미지'
                    className='w-full object-contain'
                  />
                </span>
              ) : isVideoPlay === null ? (
                <span className='w-[50px] h-[50px] flex justify-center items-center'>
                  <img
                    src='../imgs/icons/play.png'
                    alt='재생 이미지'
                    className='w-full object-contain'
                  />
                </span>
              ) : (
                <span className='w-[50px] h-[50px] flex justify-center items-center'>
                  <img
                    src='../imgs/icons/play.png'
                    alt='재생 이미지'
                    className='w-full object-contain'
                  />
                </span>
              )}
            </div>

            <div className='w-full h-full overflow-hidden flex items-center justify-center'>
              <video
                className='w-[calc(100%+8px)] h-[calc(100%+8px)] -m-[4px] object-cover cursor-pointer'
                webkit-playsinline='true'
                playsInline
                preload='auto'
                loop={true}
                ref={videoRef}
              >
                <source src='../videos/carsayo.mp4' type='video/mp4' />
              </video>
            </div>
          </div>
          <div className='flex flex-col justify-start items-start gap-2.5 py-[20px] px-4 border border-[#EEEEEE] bg-[#FFFFFFCC]'>
            <span className='text-[14px] leading-[14px]'>BRAND FILM</span>
            <strong className='text-[16px] leading-[16px] font-[600]'>
              카사요가 만든 자동차 문화
            </strong>
          </div>
        </div>

        <div className=''>
          <div
            onClick={playRandomAudio}
            style={{
              backgroundImage: "url('../imgs/png/audio_bg.png')",
            }}
            className={cn(
              'relative cursor-pointer w-full aspect-[320/180] flex justify-center items-center bg-no-repeat bg-cover bg-center bg-blend-multiply',
              audioPlay === true ? 'bg-transparent' : 'bg-[#0000001A]'
            )}
          >
            <button>
              <div className='flex flex-col justify-center items-center gap-8'>
                {audioPlay ? (
                  <span className='w-[50px] h-[50px]'>
                    <img
                      src='../imgs/icons/stop.png'
                      alt='정지 아이콘'
                      className='w-full object-contain'
                    />
                  </span>
                ) : (
                  <span className='flex justify-center items-center w-[50px] h-[50px]'>
                    <img
                      src='../imgs/icons/play.png'
                      alt='오디오 재생 아이콘'
                      className='w-full object-contain'
                    />
                  </span>
                )}
                <ul
                  className={`audio-visualizer flex justify-center items-center transition-all ${
                    audioPlay
                      ? 'playing opacity-100 absolute bottom-[20px]'
                      : 'opacity-0 absolute bottom-[0px] z-[-999]'
                  }`}
                >
                  {/* 이거 있어야 이퀄라이저 돌아감!! */}
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div>

              <audio ref={audioRef} loop />
            </button>
          </div>
          <div className='flex flex-col justify-start items-start gap-2.5 py-[20px] border border-[#EEEEEE] px-4 bg-[#FFFFFFCC]'>
            <span className='text-[14px] leading-[14px]'>BRAND AUDIO</span>
            <strong className='text-[16px] leading-[16px] font-[600]'>
              카사요가 만든 나레이션(feat.배기성)
            </strong>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

function MobileHeader() {
  const location = useLocation();

  const [isOpen, setIsOpen] = useState(false);

  const currentSubMenus = useMemo(() => {
    for (let i = 0; i < HeaderMenus.length; i++) {
      if (!HeaderMenus[i]?.subMenu?.subPages) continue;

      if (location.pathname.startsWith(HeaderMenus[i].path))
        return HeaderMenus[i].subMenu?.subPages ?? [];
    }

    return [];
  }, [location]);

  const subMenuRefs = useRef<HTMLAnchorElement[]>([]);
  useEffect(() => {
    const currentIndex = currentSubMenus.findIndex((el) =>
      location.pathname.startsWith(el.path)
    );
    if (currentIndex !== -1 && subMenuRefs.current[currentIndex]) {
      subMenuRefs.current[currentIndex].scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'center',
      });
    }
  }, [location.pathname, currentSubMenus]);

  /** 경로 바뀌면 서브메뉴 끄기 */
  useEffect(() => {
    if (isOpen) setIsOpen(false);
  }, [location.pathname]);

  // 서브메뉴 열릴 시 메인 스크롤 막기
  useEffect(() => {
    const mainPageElement = document.getElementById(
      'main'
    ) as HTMLElement | null;

    if (!mainPageElement) return;

    if (isOpen) {
      mainPageElement.style.overflow = 'hidden';
    } else {
      mainPageElement.style.overflow = 'auto';
    }

    return () => {
      mainPageElement.style.overflow = 'auto';
    };
  }, [isOpen]);

  return (
    <>
      <header className='flex-none w-full sticky top-0 left-0 border-b border-[#EEEEEE] z-20'>
        <div
          className={
            'h-[60px] relative z-20 bg-white flex w-full justify-between items-center px-5'
          }
        >
          <Link to='/' className='shrink-0 py-2'>
            <CarsayoLogo width='96' />
          </Link>
          <button
            className='focus:ring-0 focus:outline-none'
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <ul className='flex flex-col justify-center items-center w-[20px] h-[20px] gap-[8px]'>
              <li
                className={cn(
                  'w-full h-[2px] bg-[#000] transition-all',
                  isOpen &&
                    'rotate-[45deg] translate-y-[5px] w-[calc(100%+2px)]'
                )}
              ></li>
              <li
                className={cn(
                  'w-full h-[2px] bg-[#000] transition-all',
                  isOpen &&
                    '-rotate-[45deg] -translate-y-[5px] w-[calc(100%+2px)]'
                )}
              ></li>
            </ul>
            {/* <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              viewBox='0 0 30 30'
              fill='none'
            >
              <g clipPath='url(#clip0_189_32743)'>
                <path
                  d='M5 10H25'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M5 20H25'
                  stroke='black'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_189_32743'>
                  <rect width='30' height='30' fill='white' />
                </clipPath>
              </defs>
            </svg> */}
          </button>
        </div>

        <AnimatePresence mode='sync'>
          {isOpen && <MobileSubMenus />}
        </AnimatePresence>

        {currentSubMenus.length > 0 && (
          <div className='relative flex w-dvw h-[50px] gap-[30px] px-5 border-t border-[#E5E5EC] bg-[#FFF] overflow-x-scroll hide-scrollbar'>
            {currentSubMenus.map((el, index) => {
              const isCurrentTab: boolean = location.pathname.startsWith(
                el.path
              );
              return (
                <Link
                  to={el.path}
                  key={index}
                  ref={(el) => (subMenuRefs.current[index] = el!)}
                  className='relative flex flex-none h-full items-center justify-center bg-white'
                >
                  <span
                    className={cn(
                      'text-[#999999] font-normal text-[15px] leading-[15px] tracking-[-0.3px]',
                      isCurrentTab && 'text-black font-medium'
                    )}
                  >
                    {el.title}
                  </span>
                  {isCurrentTab && (
                    <div className='absolute bottom-0 w-full h-0.5 bg-[#222]'></div>
                  )}
                </Link>
              );
            })}
          </div>
        )}
      </header>
    </>
  );
}

export default function CarsayoHeader() {
  return (
    <>
      <TopBanner />
      {isBrowser && <BrowserHeader />}
      {isMobile && <MobileHeader />}
    </>
  );
}
