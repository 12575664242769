import { X } from 'lucide-react';
import { isBrowser, isMobile } from 'react-device-detect';
import { Link, useNavigate } from 'react-router-dom';

function BrowserPremiumPage() {
  return (
    <div className='flex flex-col items-center justify-center py-[60px] space-y-14 bg-cover'>
      <div className='flex flex-col items-center justify-center gap-3'>
        <div className='text-[#6E77F6] text-center text-[18px] font-semibold leading-[160%] tracking-[-0.45px]'>
          더 풍성한 카사요만의 혜택
        </div>
        <div className='text-[#222222] text-[32px] font-bold leading-[150%] tracking-[-0.8px]'>
          자동차 생활 속 특별한 혜택
        </div>
      </div>

      <div className='flex flex-col w-[1200px] gap-5'>
        <div className='grid grid-cols-2 gap-5 h-[400px]'>
          {/* 자동차 다이렉트 보험 */}
          <Link
            to={'/service?param=insurance'}
            className='relative rounded-[30px] bg-gradient-to-b from-[#1A88FF] to-[#4AA1FF] px-[50px] py-[60px]'
          >
            <div className='flex flex-col items-start gap-4'>
              <div className='text-[#F8F9FA] text-[26px] font-semibold leading-[160%]'>
                다이렉트 자동차 보험
              </div>
              <div className='text-[#F8F9FA] text-[16px] font-medium leading-[160%] tracking-[-0.45px]'>
                9개 자동차 보험사 먼저 비교하고
                <br />
                나에게 맞는 가장 저렴한 보험사를 선정한 뒤 <br />
                혜택을 더해 가입해 보세요.
              </div>

              <div className='text-white text-[16px] font-semibold leading-[160%] tracking-[-0.4px] px-5 py-3 rounded-[10px] bg-[rgba(255,255,255,0.15)] backdrop-blur-[10px] mt-4'>
                각 카드사별 최대 3만원 혜택
              </div>

              <img
                src='../imgs/png/premium/car_1.png'
                alt='카'
                width={316}
                height={200}
                className='absolute right-0 bottom-0 object-cover'
              />
            </div>
          </Link>

          {/* 자동차 사고수리 */}
          <Link
            to={'/service?param=repair'}
            className='relative rounded-[30px] bg-gradient-to-b from-[#000] to-[#222] px-[50px] py-[60px]'
          >
            <div className='flex flex-col items-start gap-4'>
              <div className='text-[#F8F9FA] text-[26px] font-semibold leading-[160%]'>
                자동차 사고수리
              </div>
              <div className='text-[#F8F9FA] text-[16px] font-medium leading-[160%] tracking-[-0.45px]'>
                자동차 수리에서 중요한 것은 경험과 기술입니다.
                <br />
                카사요가 엄선한 공업사 기준으로 가장 합리적이고 <br />
                차별화된 사고수리 서비스를 제공합니다.
              </div>

              <div className='text-white text-[16px] font-semibold leading-[160%] tracking-[-0.4px] px-5 py-3 rounded-[10px] bg-[rgba(255,255,255,0.15)] backdrop-blur-[10px] mt-4'>
                내 위치 기반 공업사 안내
              </div>

              <img
                src='../imgs/png/premium/hammer_1.png'
                alt='함마'
                width={272}
                height={245}
                className='absolute right-0 bottom-0 object-cover'
              />
            </div>
          </Link>
        </div>

        <div className='grid grid-cols-1 gap-5 h-[271px]'>
          {/* 혜택은 계속 됩니다 */}
          <div className='relative rounded-[30px] bg-[#CBE2FF] px-[50px] py-[60px]'>
            <div className='flex flex-col items-start gap-4'>
              <div className='text-[#006EF7] text-[26px] font-semibold leading-[150%] tracking-[-0.65px]'>
                혜택은 계속 됩니다.
              </div>
              <div className='text-[#222] text-[16px] font-normal leading-[160%] tracking-[-0.45px]'>
                카사요(주)는 자동차 쇼핑 통합 플랫폼이라는 지향점 아래
                <br />
                다양한 자동차 분야에서 의미 있는 성과를 창출하고자 노력하고
                있으며, <br />
                이를 통해 회사와 우리 사회의 지속가능한 발전을 위해 노력할
                것입니다.
              </div>

              <img
                src='../imgs/png/premium/items_1.png'
                alt='배경'
                className='absolute right-0 bottom-0 object-cover rounded-r-[30px]'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function MobilePremiumPage() {
  const navigate = useNavigate();
  return (
    <div className='w-dvw py-[50px] space-y-[30px] px-5'>
      {/* 상단 텍스트 영역 */}
      <div className='flex flex-col items-center justify-center space-y-3'>
        <div className='text-[#6E77F6] text-[14px] font-semibold leading-[14px] tracking-[-0.35px]'>
          더 풍성한 카사요만의 혜택
        </div>
        <div className='text-[#222] text-[24px] font-semibold leading-[36px] tracking-[-0.6px]'>
          자동차 생활 속 특별한 혜택
        </div>
      </div>

      {/* 자동차 다이렉트 보험 */}
      <div
        onClick={() => {
          navigate('/service');
        }}
        className='relative rounded-[30px] bg-gradient-to-b from-[#1A88FF] to-[#4AA1FF] px-[24px] py-[34px] h-[360px] tiny:h-[340px]'
      >
        <div className='flex flex-col items-start gap-3 tiny:gap-2'>
          <div className='text-[#F8F9FA] text-[18px] tiny:text-[15px] font-semibold leading-[28.8px]'>
            다이렉트 자동차 보험
          </div>
          <div className='text-[#F8F9FA] text-[15px] tiny:text-[12.5px] tiny:tracking-[-0.6px] tiny:leading-[22px] font-medium leading-[24px] tracking-[-0.375px]'>
            9개 자동차 보험사 먼저 비교하고
            <br />
            나에게 맞는 가장 저렴한 보험사를 선정한 뒤 <br />
            혜택을 더해 가입해 보세요.
          </div>

          <div className='text-white text-[14px] tiny:text-[13px] tiny:py-1.5 font-semibold leading-[22.4px] tracking-[-0.35px] px-2.5 py-2 rounded-[10px] bg-[rgba(255,255,255,0.15)] backdrop-blur-[10px] mt-1'>
            각 카드사별 최대 3만원 혜택
          </div>

          <img
            src='../imgs/png/premium/m_car_1.png'
            alt='카'
            width={202}
            height={202}
            className='absolute right-0 bottom-0 object-cover'
          />
        </div>
      </div>

      {/* 자동차 사고수리 */}
      <Link to={'/service'}>
        <div className='mt-[30px] relative rounded-[30px] bg-gradient-to-b from-[#000] to-[#222] px-[24px] py-[34px] h-[360px] tiny:h-[340px]'>
          <div className='flex flex-col items-start gap-3 tiny:gap-2'>
            <div className='text-[#F8F9FA] text-[18px] tiny:text-[15px] font-semibold leading-[28.8px]'>
              자동차 사고수리
            </div>
            <div className='text-[#F8F9FA] text-[15px] tiny:text-[12.5px] tiny:tracking-[-0.6px] tiny:leading-[22px] font-medium leading-[24px] tracking-[-0.375px]'>
              자동차 수리에서 중요한 것은 경험과 기술입니다.
              <br />
              카사요가 엄선한 공업사 기준으로 가장 합리적이고 <br />
              차별화된 사고수리 서비스를 제공합니다.
            </div>

            <div className='text-white text-[14px] tiny:text-[13px] tiny:py-1.5 font-semibold leading-[22.4px] tracking-[-0.35px] px-2.5 py-2 rounded-[10px] bg-[rgba(255,255,255,0.15)] backdrop-blur-[10px] mt-1'>
              내 위치 기반 공업사 안내
            </div>

            <img
              src='../imgs/png/premium/m_hammer_1.png'
              alt='카'
              width={180}
              height={180}
              className='absolute right-0 bottom-0 object-cover'
            />
          </div>
        </div>
      </Link>

      {/* 혜택은 계속 됩니다 */}
      <div
        style={{
          backgroundImage: 'url("/imgs/png/premium/m_items_1.png")',
        }}
        className='relative bg-right-bottom bg-contain bg-no-repeat rounded-[30px] bg-[#CBE2FF] px-[24px] py-[34px] h-[360px] tiny:h-[340px]'
      >
        <div className='flex flex-col items-start gap-3 tiny:gap-2'>
          <div className='text-[#006EF7] text-[18px] font-semibold leading-[28.8px]'>
            혜택은 계속 됩니다.
          </div>
          <div className='text-[#222222] max-w-[480px] break-keep text-[15px] tiny:text-[12.5px] tiny:tracking-[-0.6px] tiny:leading-[22px] font-medium leading-[24px] tracking-[-0.375px]'>
            카사요(주)는 자동차 쇼핑 통합 플랫폼이라는 지향점 아래 다양한 자동차
            분야에서 의미 있는 성과를 창출하고자 노력하고 있으며, 이를 통해
            회사와 우리 사회의 지속가능한 발전을 위해 노력할 것입니다.
          </div>
        </div>
      </div>
    </div>
  );
}

export default function PremiumPage() {
  return (
    <>
      {isBrowser && <BrowserPremiumPage />}
      {isMobile && <MobilePremiumPage />}
    </>
  );
}
