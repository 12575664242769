import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom';
import { cn } from 'util/index';

const items = [
  {
    title: '다이렉트 자동차보험',
    description: '최저가로 가입!',
    path: 'service?param=insurance',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='36'
        viewBox='0 0 36 36'
        fill='none'
      >
        <path
          d='M20.4662 32.3121C19.219 33.2204 17.5194 33.2204 16.2722 32.3121C11.3749 28.7456 4.13428 22.6341 4.13428 12.0799V9.77276C4.13428 9.012 4.67754 8.36369 5.41836 8.1906C9.78016 7.17146 13.8308 5.17413 17.2864 2.34354C17.914 1.82945 18.8244 1.82944 19.4519 2.3436C22.9072 5.17458 26.9573 7.17513 31.3186 8.19134C32.0601 8.36411 32.6041 9.0127 32.6041 9.77404V12.0799C32.6041 22.6341 25.3636 28.7456 20.4662 32.3121Z'
          fill='#3395FF'
        />
        <path
          d='M13.6182 18.1384L16.9108 21.4311L23.4962 14.8457'
          stroke='white'
          strokeWidth='2.88'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    ),
  },
  {
    title: '신차 구매',
    description: '원하는 차량의 견적을 한 번에!',
    path: 'service?param=newcar',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
      >
        <path
          d='M10.0508 25.5967C10.0508 23.9618 8.72546 22.6365 7.09058 22.6365C5.4557 22.6365 4.13037 23.9618 4.13037 25.5967V28.5089C4.13037 30.1438 5.4557 31.4691 7.09058 31.4691C8.72546 31.4691 10.0508 30.1438 10.0508 28.5089V25.5967Z'
          fill='#333331'
        />
        <path
          d='M31.2793 25.5967C31.2793 23.9618 29.954 22.6365 28.3191 22.6365C26.6842 22.6365 25.3589 23.9618 25.3589 25.5967V28.5089C25.3589 30.1438 26.6842 31.4691 28.3191 31.4691C29.954 31.4691 31.2793 30.1438 31.2793 28.5089V25.5967Z'
          fill='#333331'
        />
        <path
          d='M33.0934 15.8247C34.039 15.8247 34.8064 15.0573 34.8064 14.1117C34.8064 13.166 34.0458 12.3986 33.0934 12.3986H31.5721C30.8938 12.3986 30.3182 12.796 30.0372 13.3648C29.9687 13.2551 29.907 13.1386 29.8454 13.029C29.4137 12.2958 28.6256 8.61609 28.6256 8.61609C28.6256 8.61609 28.1802 3.82631 23.0752 3.4974C21.1977 3.38091 19.9574 3.3535 17.7099 3.36721C15.4623 3.3535 14.222 3.37406 12.3445 3.4974C7.2395 3.81946 6.7941 8.61609 6.7941 8.61609C6.7941 8.61609 6.00608 12.2958 5.57439 13.029C5.51272 13.1386 5.44419 13.2483 5.38252 13.3648C5.10158 12.796 4.52598 12.3986 3.85445 12.3986H2.33323C1.37391 12.3917 0.606445 13.1592 0.606445 14.1048C0.606445 15.0504 1.36705 15.8179 2.31953 15.8179H2.88142C1.79875 16.9896 1.78505 18.6274 1.78505 18.6274C1.78505 18.6274 1.41502 20.4432 1.7919 23.9311C2.17563 27.5011 6.73928 27.3093 6.73928 27.3093H28.6805C28.6805 27.3093 33.2441 27.508 33.6278 23.9311C34.0047 20.4432 33.6347 18.6274 33.6347 18.6274C33.6347 18.6274 33.621 16.9896 32.5383 15.8179H33.0934V15.8247Z'
          fill='#4860FF'
        />
        <path
          d='M25.8357 10.4738C25.8357 10.4738 25.2258 6.50626 21.6969 6.3418C20.2921 6.27328 19.3739 6.22531 17.702 6.23902C16.03 6.22531 15.1049 6.22531 13.707 6.3418C10.1438 6.63645 9.56824 10.4738 9.56824 10.4738C9.56824 10.4738 9.16395 12.228 8.86245 13.4819C8.74596 13.9684 9.14339 14.0233 9.14339 14.0233C9.14339 14.0233 9.87659 14.1123 17.7979 14.1123C25.7192 14.1123 26.3016 14.0233 26.3016 14.0233C26.3016 14.0233 26.6648 14.0233 26.5415 13.4956C26.2879 12.4267 25.8357 10.4806 25.8357 10.4806V10.4738Z'
          fill='white'
        />
        <path
          d='M4.7409 22.698C4.2955 22.698 3.8775 22.4034 3.75416 21.9511L3.34302 20.4916C3.18542 19.9434 3.50748 19.3746 4.05566 19.2239C4.597 19.0663 5.17259 19.3883 5.32335 19.9365L5.73449 21.3961C5.89209 21.9443 5.57003 22.513 5.02184 22.6638C4.92591 22.6912 4.83683 22.7049 4.7409 22.7049V22.698Z'
          fill='#FFDE8B'
        />
        <path
          d='M30.6772 22.6981C30.5813 22.6981 30.4922 22.6844 30.3963 22.6569C29.8481 22.4993 29.5329 21.9375 29.6836 21.3893L30.0948 19.9297C30.2524 19.3815 30.8211 19.0663 31.3624 19.2171C31.9106 19.3747 32.2258 19.9366 32.0751 20.4848L31.664 21.9443C31.5338 22.3966 31.1226 22.6912 30.6772 22.6912V22.6981Z'
          fill='#FFDE8B'
        />
        <path
          d='M22.1436 22.2739H13.2767C12.5198 22.2739 11.9062 22.8875 11.9062 23.6444V23.939C11.9062 24.6959 12.5198 25.3095 13.2767 25.3095H22.1436C22.9005 25.3095 23.5141 24.6959 23.5141 23.939V23.6444C23.5141 22.8875 22.9005 22.2739 22.1436 22.2739Z'
          fill='#1938FF'
        />
      </svg>
    ),
  },
  {
    title: '내 차 팔기',
    description: '감가 없이 안전하게!',
    path: 'service?param=sellcar',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='30'
        height='34'
        viewBox='0 0 30 34'
        fill='none'
      >
        <g clipPath='url(#clip0_1600_42117)'>
          <path
            d='M26.272 26.1566V3.26557C26.272 2.10028 25.0786 1.31612 24.0091 1.77864L20.3998 3.33939C19.9895 3.51682 19.5241 3.51682 19.1138 3.33939L13.8846 1.0781C13.4743 0.900672 13.0089 0.900672 12.5986 1.0781L7.36939 3.33939C6.95909 3.51682 6.49371 3.51682 6.0834 3.33939L2.47418 1.77864C1.4046 1.31612 0.211182 2.10028 0.211182 3.26557V29.6783C0.211182 31.6223 1.78892 33.2001 3.73292 33.2001H26.272V26.1566Z'
            fill='#7273F9'
          />
          <path
            d='M26.272 33.2005C28.216 33.2005 29.7938 31.6227 29.7938 29.6787C29.7938 27.7347 28.216 26.157 26.272 26.157H3.73291C5.67691 26.157 7.25465 27.7347 7.25465 29.6787C7.25465 31.6227 5.67691 33.2005 3.73291 33.2005H26.272Z'
            fill='#B8B8FF'
          />
          <path
            d='M8.64173 16.7422H6.77284C6.0615 16.7422 5.47949 16.1602 5.47949 15.4489C5.47949 14.7375 6.0615 14.1555 6.77284 14.1555H8.64173C9.35307 14.1555 9.93507 14.7375 9.93507 15.4489C9.93507 16.1602 9.35307 16.7422 8.64173 16.7422Z'
            fill='white'
          />
          <path
            d='M19.7069 16.7422H17.838C17.1267 16.7422 16.5447 16.1602 16.5447 15.4489C16.5447 14.7375 17.1267 14.1555 17.838 14.1555H19.7069C20.4183 14.1555 21.0003 14.7375 21.0003 15.4489C21.0003 16.1602 20.4183 16.7422 19.7069 16.7422Z'
            fill='white'
          />
          <path
            d='M16.1125 21.3983C15.5887 21.3983 15.1166 21.0815 14.9161 20.5965L13.2348 16.516L11.5599 20.5965C11.3594 21.0879 10.868 21.4048 10.3377 21.3983C9.80742 21.3919 9.33535 21.0556 9.14781 20.5577L6.27658 12.7976C6.03085 12.1251 6.37359 11.3814 7.03966 11.1356C7.70573 10.8899 8.45587 11.2262 8.70161 11.8987L10.4282 16.5548L12.0449 12.6295C12.2454 12.1445 12.7175 11.8276 13.2413 11.8276C13.7651 11.8276 14.2371 12.1445 14.4376 12.6295L16.0543 16.5548L17.7809 11.8987C18.0266 11.2262 18.7703 10.8834 19.4429 11.1356C20.1154 11.3814 20.4517 12.1251 20.2059 12.7976L17.3347 20.5577C17.1472 21.0556 16.6751 21.3919 16.1448 21.3983C16.1384 21.3983 16.1319 21.3983 16.119 21.3983H16.1125Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_1600_42117'>
            <rect
              width='29.5826'
              height='32.4'
              fill='white'
              transform='translate(0.208008 0.800049)'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
  {
    title: '자동차 사고수리',
    description: '카사요가 엄선한 1급 공업사',
    path: 'service?param=repair',
    svg: (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='36'
        height='35'
        viewBox='0 0 36 35'
        fill='none'
      >
        <g clipPath='url(#clip0_1600_42134)'>
          <path
            d='M25.192 12.3783L23.9078 11.0667L22.3077 9.41846L14.156 16.6774L19.5126 21.9379L26.7922 14.0265L25.192 12.3783Z'
            fill='#DFE1E3'
          />
          <path
            d='M29.1126 8.87652C29.6551 9.43966 30.5617 9.45339 31.1248 8.90399L33.8855 6.22568C33.8855 6.22568 34.3525 5.80677 34.586 6.45231C35.5337 8.88339 34.998 11.7471 33.0133 13.6769C30.3625 16.2522 26.1253 16.1904 23.55 13.5464C22.8426 12.8185 22.3344 11.9738 22.0254 11.0741L27.1897 6.89869L29.1126 8.87652Z'
            fill='#C2C8CF'
          />
          <path
            d='M28.3628 7.55758C27.7448 6.92577 27.7585 5.89565 28.3972 5.28444L31.515 2.24902C31.515 2.24902 32.0026 1.73396 31.2815 1.44553C28.562 0.298658 25.3068 0.806851 23.0543 2.99071C20.0532 5.90938 19.9845 10.7029 22.9032 13.704C23.5831 14.4044 24.3728 14.947 25.2107 15.3247C25.3892 15.414 25.8287 15.0775 25.8287 15.0775L28.5276 8.75251C28.5276 8.75251 28.6787 8.39541 28.6307 8.05203C28.5895 7.79794 28.356 7.55758 28.356 7.55758H28.3628Z'
            fill='#DFE1E3'
          />
          <path
            d='M2.2807 25.838L13.1588 15.8184C15.0405 13.9848 16.7573 15.3171 18.5841 17.1988L18.9068 17.5353C20.7405 19.417 22.0178 21.1682 20.1361 22.9949L9.81432 33.5845C7.93263 35.4182 4.89721 35.3701 3.06359 33.4884L2.18456 32.5819C0.350943 30.7002 0.399015 27.6648 2.2807 25.8312V25.838Z'
            fill='#008E6F'
          />
          <path
            d='M6.59457 30.7009C6.23747 30.7009 5.88036 30.5636 5.61253 30.282C5.08373 29.7395 5.09747 28.8673 5.64 28.3385L6.65638 27.3496C7.19891 26.8208 8.07108 26.8345 8.59988 27.3771C9.12867 27.9196 9.11494 28.7918 8.57241 29.3205L7.55602 30.3095C7.28819 30.5704 6.94482 30.7009 6.60144 30.7009H6.59457Z'
            fill='white'
          />
          <path
            d='M22.2925 15.022C22.9372 15.022 23.4599 14.4993 23.4599 13.8545C23.4599 13.2097 22.9372 12.687 22.2925 12.687C21.6477 12.687 21.125 13.2097 21.125 13.8545C21.125 14.4993 21.6477 15.022 22.2925 15.022Z'
            fill='white'
          />
        </g>
        <defs>
          <clipPath id='clip0_1600_42134'>
            <rect
              width='34.2'
              height='34.0833'
              fill='white'
              transform='translate(0.8396 0.847168)'
            />
          </clipPath>
        </defs>
      </svg>
    ),
  },
];

export default function ServiceGo() {
  return (
    <section className='w-[1200px] flex gap-5'>
      {/* 왼쪽 */}
      <div className='flex w-[760px] flex-col flex-none bg-[#E2F0FF]'>
        <Link
          to={'/service?param=insurance'}
          className='relative py-[28px] px-10'
        >
          <div className='absolute right-[46px] bottom-2'>
            <img
              className='w-[200px] h-[200px]'
              src='imgs/icons/main/insuBannerImg.png'
              alt='car'
            />
          </div>
          <div className='text-[28px] font-semibold leading-[150%] tracking-[-0.7px]'>
            다이렉트 자동차보험
          </div>
          <div className='text-[16px] font-normal leading-[160%] tracking-[-0.4px] text-[#222] pt-1.5 pb-4'>
            보험사별로 금액 비교하고 직접 가입하면 더욱 저렴합니다.
          </div>
          <div className='rounded-full bg-[#0079FC] flex items-center justify-center w-[125px] h-10'>
            <span className='text-[14px] font-semibold leading-[150%] tracking-[-0.35px] text-white'>
              내 보험료 확인하기
            </span>
          </div>
        </Link>
        <div className='relative grid grid-cols-2 h-[354px] bg-[linear-gradient(180deg,rgba(255,255,255,0.90)_0%,rgba(255,255,255,0.50)_100%)] pt-2.5'>
          <div className='absolute w-full flex items-center justify-center bottom-0 z-10 mb-[67px]'>
            <img
              className='w-[52px] h-[52px]'
              src='imgs/icons/main/rightIcon.png'
              alt='car'
            />
          </div>
          <div
            className={cn(
              'relative flex items-center justify-start flex-col',
              'border-r-[1px] border-[#D6EAFF]'
            )}
          >
            <div className='inline-flex items-center gap-2 text-[#222] font-semibold text-[16px] leading-[24px] tracking-[-0.4px] border-b-[1px] border-[#D6EAFF] py-[14px] w-[300px]'>
              <span className='text-[#0079FC]'>STEP 1</span>나에게 맞는 최저가
              보험사 확인 후
            </div>
            <div className='mt-[30px] text-[#222] text-center text-[14px] font-normal leading-[21px] tracking-[-0.35px]'>
              <div>가장 저렴한 보험사를</div>
              <div className='inline-flex'>
                <div className='relative text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px]'>
                  <div className='absolute bottom-0 bg-[#D6EAFF] w-full h-[11px]'></div>
                  <div className='relative'>비교 후 확인</div>
                </div>
                하세요.
              </div>
            </div>

            <div className='absolute bottom-0'>
              <img
                className='w-[198px] h-[204px]'
                src='imgs/icons/main/phoneEx1.png'
                alt='car'
              />
            </div>
          </div>
          <div className='relative flex items-center justify-start flex-col'>
            <div className='inline-flex items-center gap-2 text-[#222] font-semibold text-[16px] leading-[24px] tracking-[-0.4px] border-b-[1px] border-[#D6EAFF] py-[14px] w-[300px]'>
              <span className='text-[#0079FC]'>STEP 2</span>직접 가입하기
            </div>

            <div className='mt-[30px] text-[#222] text-center text-[14px] font-normal leading-[21px] tracking-[-0.35px]'>
              <div className='inline-flex'>
                원하는 보험사로
                <div className='relative text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] ml-[2px]'>
                  <div className='absolute bottom-0 bg-[#D6EAFF] w-full h-[11px]'></div>
                  <div className='relative'>직접 가입</div>
                </div>
                하면
              </div>
              <div>더 알뜰하게, 저렴하게 가입합니다.</div>
            </div>
            <div className='absolute bottom-0'>
              <img
                className='w-[198px] h-[204px]'
                src='imgs/icons/main/phoneEx2.png'
                alt='car'
              />
            </div>
          </div>
        </div>
      </div>
      {/* 오른쪽 */}
      <div className='flex-auto flex flex-col gap-5'>
        <Link
          to={'/service?param=newcar'}
          className='bg-[#F8F9FA] flex-auto relative'
        >
          <div className='flex flex-col h-full justify-between pt-[30px] pb-[23px] px-[24px]'>
            <div className='flex flex-col gap-1'>
              <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                신차 구매
              </div>
              <div className='text-[#222] text-[18px] font-semibold leading-[150%] tracking-[-0.45px]'>
                원하는 차량의 견적을 한 번에!
              </div>
            </div>
            <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
              서비스 이용하기
            </div>
          </div>
          <div className='absolute top-0 h-full right-[5px] flex items-center justify-center'>
            <img
              className='w-[120px] h-[120px]'
              src='imgs/icons/main/note.png'
              alt='car'
            />
          </div>
        </Link>
        <Link
          to={'/service?param=sellcar'}
          className='bg-[#EDF1FF] flex-auto relative'
        >
          <div className='flex flex-col h-full justify-between pt-[30px] pb-[23px] px-[24px]'>
            <div className='flex flex-col gap-1'>
              <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                내 차 팔기
              </div>
              <div className='text-[#222] text-[18px] font-semibold leading-[150%] tracking-[-0.45px]'>
                시세부터 판매까지 감가 없이 안전하게!
              </div>
            </div>
            <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
              서비스 이용하기
            </div>
          </div>
          <div className='absolute top-0 h-full right-[5px] flex items-center justify-center'>
            <img
              className='w-[120px] h-[120px]'
              src='imgs/icons/main/phone.png'
              alt='car'
            />
          </div>
        </Link>
        <Link
          to={'/service?param=repair'}
          className='bg-[#E4F7F3] flex-auto relative'
        >
          <div className='flex flex-col h-full justify-between pt-[30px] pb-[23px] px-[24px]'>
            <div className='flex flex-col gap-1'>
              <div className='text-[#222] text-[15px] font-medium leading-[150%] tracking-[-0.375px]'>
                자동차 사고수리
              </div>
              <div className='text-[#222] text-[18px] font-semibold leading-[150%] tracking-[-0.45px]'>
                카사요가 엄선한 1급 공업사
              </div>
            </div>
            <div className='w-[110px] h-[40px] bg-white rounded-full text-[#222] text-[14px] font-semibold leading-[150%] tracking-[-0.35px] flex items-center justify-center'>
              서비스 이용하기
            </div>
          </div>
          <div className='absolute top-0 h-full right-[5px] flex items-center justify-center'>
            <img
              className='w-[120px] h-[120px]'
              src='imgs/icons/main/hammer.png'
              alt='car'
            />
          </div>
        </Link>
      </div>

      {/* <div className='w-full h-full flex flex-col justify-start items-start gap-6'>
        <motion.h3
          initial={{ opacity: 0, x: -20 }}
          whileInView={{
            opacity: 1,
            x: 0,
            transition: { delay: 0, duration: 0.5 },
          }}
          className='text-[32px] leading-[48px] font-[700]'
        >
          서비스 바로가기
        </motion.h3>
        <motion.ul
          initial={{ opacity: 0 }}
          whileInView={{
            opacity: 1,
            transition: { delay: 0.1, duration: 1 },
          }}
          className='w-full grid grid-cols-4 gap-3'
        >
          {items.map((item, index) => {
            return (
              <div
                key={index}
                className='cursor-pointer bg-[#F8F9FA] p-4 h-[92px] flex gap-4 items-center justify-center'
                onClick={() => {
                  navigate(item.path);
                }}
              >
                <div className='flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white'>
                  {item.svg}
                </div>
                <div className='flex-auto gap-2.5 flex flex-col justify-between items-start'>
                  <div className='text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.45px]'>
                    {item.title}
                  </div>
                  <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.375px]'>
                    {item.description}
                  </div>
                </div>
              </div>
            );
          })}
        </motion.ul>
      </div> */}
    </section>
  );
}
